import { createSelector } from '@ngrx/store';
import { Company } from '@myrtls/api-interfaces';
import { AppState } from '../index';
import { CompaniesState } from './companies.reducer';

const $companiesState = (state: AppState): CompaniesState => state.companies;

export const $companiesLoading = createSelector(
    $companiesState,
    ({ loading }) => loading,
);

export const $companiesLoaded = createSelector(
    $companiesState,
    ({ loaded }) => loaded,
);

export const $companies = createSelector(
    $companiesState,
    ({ companies }): Company[] => companies,
);

export const $selectedCompanyId = createSelector(
    $companiesState,
    ({ selectedCompanyId }) => selectedCompanyId,
);

export const $selectedCompany = createSelector(
    $companies,
    $selectedCompanyId,
    (companies: Company[], selectedCompanyId: string | null): Company | null =>
        companies.find(c => c.id === selectedCompanyId) || null,
);

export const $selectedCompanyName = createSelector(
    $selectedCompany,
    (company: Company | null): string => company?.name || 'None',
);
