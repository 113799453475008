<h1 mat-dialog-title>[{{ data.date }}] Alert List</h1>
<div mat-dialog-content class="mt-6 min-w-[850px]">
    <mat-accordion multi displayMode="flat">
        @for (group of data.alerts | groupByProperty: 'metric' | keyvalue; track group) {
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-description class="w-4/5">
                        <div class="flex justify-between items-center w-full">
                            <div class="flex text-dark-900 font-semibold min-w-40">{{ $any(group.value)[0].metricSettings?.label }}</div>
                            <div class="flex">
                                @if (count($any(group.value)); as count) {
                                    @if (count.pending > 0) {
                                        Pending:
                                        <div class="text-error mx-2">{{ count.pending }}</div>
                                    }
                                    @if (count.resolved > 0) {
                                        Resolved:
                                        <div class="text-success mx-2">{{ count.resolved }}</div>
                                    }
                                }
                            </div>
                            <div class="flex grow justify-end">
                                @if (count($any(group.value)).pending > 0 && $any(group.value)[0].metricSettings?.alertTemplate; as alertTemplate) {
                                    <div class="py-2 px-4 rounded-md bg-warning-100 border-warning-400 border" [ngClass]="{ 'bg-warning-100 border-warning-400': alertTemplate.type === 'warning', 'bg-error-100 border-error-400': alertTemplate.type === 'error' }">
                                        {{ alertTemplate.action }} <a *ngIf="alertTemplate.link" (click)="$event.stopPropagation()" target="_blank" href="{{ alertTemplate.link }}">Learn more</a>
                                    </div>
                                }
                            </div>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <table mat-table [dataSource]="$any(group.value) | revertArray | mergeAlerts" multiTemplateDataRows>
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>ID</th>
                        <td mat-cell *matCellDef="let element">{{ element.metadata || element.metricSettings.label }}</td>
                    </ng-container>
                    <ng-container matColumnDef="resolved">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element">
                            @if (element.pending) {
                                <mat-chip class="bg-error text-white">Pending</mat-chip>
                            } @else {
                                <mat-chip class="bg-success text-white">Resolved</mat-chip>
                            }
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="count">
                        <th mat-header-cell *matHeaderCellDef>Occurences</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.count }}
                            @if (element.count > 1) {
                                times
                            } @else {
                                time
                            }
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef>Last Occurence</th>
                        <td mat-cell *matCellDef="let element">
                            <myrtls-date-interval
                                class="alert-interval"
                                [dateInterval]="{
                                    from: element.from,
                                    to: element.to,
                                    date: data.date,
                                }"
                            ></myrtls-date-interval>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="duration">
                        <th mat-header-cell *matHeaderCellDef>Last Duration</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.duration }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                    <tr mat-row *matRowDef="let element; columns: columnsToDisplay"></tr>
                </table>
            </mat-expansion-panel>
        }
    </mat-accordion>
</div>
<div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="data.date" cdkFocusInitial>Show in History</button>
    <button mat-button mat-dialog-close cdkFocusInitial>Close</button>
</div>
