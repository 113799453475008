import { Pipe, PipeTransform } from '@angular/core';
import { EChartsOption } from 'echarts';
import { TableOptions } from '../../store/sites/sites.selectors';
import { MetricGroup } from '../models/metric-group.enum';

@Pipe({ name: 'sortMetricsByIdsArray' })
export class SortMetricsByIdsArrayPipe implements PipeTransform {
    transform(
        array: (
            | {
                  id: string;
                  group: MetricGroup;
                  name: string;
                  info?: string;
                  options?: EChartsOption;
                  tableOptions?: TableOptions;
              }
            | null
            | false
        )[],
        ids: string[],
    ): (
        | {
              id: string;
              name: string;
              group: MetricGroup;
              info?: string;
              options?: EChartsOption;
              tableOptions?: TableOptions;
          }
        | null
        | false
    )[] {
        return array.sort((a, b) => {
            if (a === null || b === null || a === false || b === false) {
                return 0;
            }

            return ids.indexOf(a.id) - ids.indexOf(b.id);
        });
    }
}
