import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTabsModule } from '@angular/material/tabs';
import {
    MatRadioModule,
    MAT_RADIO_DEFAULT_OPTIONS,
} from '@angular/material/radio';
import { SiteDeploymentComponent } from './site-deployment.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    declarations: [SiteDeploymentComponent],
    exports: [SiteDeploymentComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatRadioModule,
        MatDialogModule,
        MatSelectModule,
        MatIconModule,
        ClipboardModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatStepperModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatMenuModule,
    ],
    providers: [
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'primary' },
        },
    ],
})
export class SiteDeploymentModule {}
