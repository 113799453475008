import {
    DeviceCare,
    MetricMapping,
    ThresholdType,
} from '@myrtls/api-interfaces';

export interface SettingsDataModel {
    metrics: SettingsMetricModel[];
    deviceCare: DeviceCare | undefined;
}

export interface SettingsMetricModel {
    id: string;
    label: string;
    alert: boolean;
    report: boolean;
    state?: string;
    dashboard: boolean;
    history: boolean;
    hasDashboardTemplate: boolean;
    hasHistoryTemplate: boolean;
    hasAlertTemplate: boolean;
    alertTemplate?: {
        alertText: string;
        resolveText: string;
        type: 'error' | 'warning';
        action?: string;
        link?: string;
    };
    mappings: MetricMapping[];
    threshold?: {
        value: number;
        unit: string;
        symbol: string;
        valueAbbreviation?: number;
        state?: boolean;
        editable: boolean;
    };
    overridable: boolean;
}

export const ThresholdToSymbolMap = {
    [ThresholdType.ABOVE]: '>',
    [ThresholdType.BELOW]: '<',
    [ThresholdType.EQUAL_OR_ABOVE]: '>=',
};
