<div class="single-stat-widget-container" [@listAnimation]="(data || []).length">
    <div *ngIf="(data || []).length" class="single-stat-header">Other Metrics</div>
    <ng-container *ngFor="let item of data || []; trackBy: trackByIndex">
        <div class="single-stat-container" [ngClass]="item.loading ? 'undefined' : item.status" [class.full]="item.full" (click)="showPopup(item.id, item.version)">
            <mat-icon *ngIf="showInfo && item.info" class="helper-icon" (click)="displayInfo($event, item.info)" fontSet="material-icons-outlined">help_outline</mat-icon>
            <div class="single-stat-label">
                {{ item.label }}
            </div>
            <div class="single-stat-value">
                <span *ngIf="!item.loading; else loading"
                    >{{ item.value }}<span *ngIf="item.threshold !== undefined && item.value !== 'NO DATA'">/ {{ item.threshold }}</span></span
                >
                <ng-template #loading><mat-progress-spinner class="loading-container" [diameter]="20" [mode]="'indeterminate'"> </mat-progress-spinner></ng-template>
            </div>
        </div>
    </ng-container>
</div>
