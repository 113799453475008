import {
    animate,
    query,
    stagger,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteFeature } from '@myrtls/api-interfaces';
import { Store } from '@ngrx/store';
import { DashboardRatioBarValueModel } from '../../shared/models/dashboard-value.model';
import {
    INFO_MODAL_WIDTH,
    METRIC_MODAL_MAPPINGS,
} from '../../shared/utils/constants';
import { AppState } from '../../store';
import { setAlertsOnly } from '../../store/sites/sites.actions';
import { InfoModalComponent } from '../info-modal/info-modal.component';

@Component({
    selector: 'myrtls-ratio-bar-widget',
    templateUrl: './ratio-bar-widget.component.html',
    styleUrls: ['./ratio-bar-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('listAnimation', [
            transition('* => *', [
                query(
                    ':enter',
                    [
                        style({ opacity: 0, transform: 'translateY(-10px)' }),
                        stagger(100, [
                            animate(
                                250,
                                style({ opacity: 1, transform: 'none' }),
                            ),
                        ]),
                    ],
                    { optional: true },
                ),
            ]),
        ]),
        trigger('scaleAnimation', [
            transition(':enter', [
                style({ transform: 'scaleX(0)', opacity: 0 }),
                animate(
                    '500ms ease-in-out',
                    style({ transform: 'scaleX(1)', opacity: 1 }),
                ),
            ]),
        ]),
    ],
})
export class RatioBarWidgetComponent {
    @Input() data: DashboardRatioBarValueModel[] | null = [];
    @Input() showInfo = true;

    constructor(
        private readonly store: Store<AppState>,
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    displayInfo(info: string) {
        this.dialog.open(InfoModalComponent, {
            width: INFO_MODAL_WIDTH,
            data: {
                content: info,
            },
        });
    }

    showInHistory(id?: string) {
        if (!id) {
            return;
        }

        this.store.dispatch(setAlertsOnly({ alertsOnly: false }));
        this.router.navigate([`../${SiteFeature.DEVICE_CARE_HISTORY}`], {
            relativeTo: this.route,
            queryParams: { focus: id },
        });
    }

    showPopup(id?: string, version?: string) {
        const popupComponent = METRIC_MODAL_MAPPINGS[`${id}:${version}`];

        if (popupComponent) {
            this.dialog.open(popupComponent, {
                width: INFO_MODAL_WIDTH,
            });
        }
    }

    trackByIndex(index: number) {
        return index;
    }
}
