import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DeviceCareAdvancedModal } from './device-care-advanced.modal';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '../../shared/shared.module';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
    declarations: [DeviceCareAdvancedModal],
    exports: [DeviceCareAdvancedModal],
    imports: [
        SharedModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatSelectModule,
        MatButtonToggleModule,
        MatTableModule,
        MatSortModule,
    ],
})
export class DeviceCareAdvancedModalModule {}
