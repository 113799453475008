<h2 mat-dialog-title>Advanced Configuration - {{ (metric$ | async)?.name }}</h2>

<div mat-dialog-content>
    <mat-card appearance="outlined" *ngIf="columns.length === 0">
        <mat-card-content>No data to display...</mat-card-content>
    </mat-card>

    <div class="mb-4 flex flex-col justify-between items-end" *ngIf="columns.length !== 0">
        <mat-form-field>
            <mat-label>Filter data</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Search..." #input />
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
    </div>

    <table mat-table [dataSource]="controls || []" multiTemplateDataRows matSort (matSortChange)="sortData($event)" *ngIf="columns.length !== 0">
        <ng-container *ngFor="let column of dynamicColumns" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column | titlecase }}</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
                {{ element.get(column)?.value }}
            </td>
        </ng-container>

        <ng-container matColumnDef="enabled">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Enabled</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
                <mat-checkbox color="primary" [formControlName]="'enabled'"></mat-checkbox>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns" class="font-semibold"></tr>
        <tr mat-row *matRowDef="let element; columns: columns"></tr>
    </table>
</div>

<div class="flex items-center justify-end" mat-dialog-actions>
    <button mat-button mat-dialog-close>CANCEL</button>
    <button color="primary" mat-button (click)="save()" [disabled]="!enabledFormGroup?.valid || columns.length === 0">SAVE</button>
</div>
