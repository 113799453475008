import { Component } from '@angular/core';
import { InfluxdbService } from '../../core/services/influxdb.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import {
    $deviceCareConfig,
    $metricWithData,
} from '../../store/sites/sites.selectors';
import { filter, withLatestFrom } from 'rxjs';

@Component({
    selector: 'myrtls-hdd-v1-modal',
    templateUrl: './hdd-v1.modal.html',
    styleUrls: [],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class HddV1Modal {
    metric = 'hdd';
    hddQuery = `from(bucket: "$BUCKET")
    |> range(start: -1h)
    |> filter(fn: (r) => r["_field"] == "free" or r["_field"] == "total")
    |> filter(fn: (r) => r["path"] == "/")
    |> last()`;

    constructor(
        private store: Store<AppState>,
        private influxdbService: InfluxdbService,
    ) {
        this.store
            .select($deviceCareConfig)
            .pipe(
                filter(config => !!config),
                withLatestFrom(this.store.select($metricWithData(this.metric))),
            )
            .subscribe(async ([config, metric]) => {
                if (!config || !metric) return;

                // threshold for hdd can be found in metric (for the color visualisation)

                console.log(config, metric);
                console.log(
                    await this.influxdbService.getDashboardChartData({
                        apiUrl: config.influx.host,
                        query: this.hddQuery,
                        org: config.influx.organization,
                        token: config.influx.readToken,
                        bucket: config.influx.bucket,
                        overrides:
                            metric.overrides?.map(override => override.value) ||
                            [],
                    }),
                );
            });
    }
}
