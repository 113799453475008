import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Company } from '@myrtls/api-interfaces';

export const getCompaniesRequestAction = createAction(
    '[Companies] Get Companies Request',
);

export const getCompaniesSuccessAction = createAction(
    '[Companies] Get Companies Success',
    props<{ companies: Company[] }>(),
);

export const getCompaniesFailureAction = createAction(
    '[Companies] Get Companies Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const selectCompanyAction = createAction(
    '[Companies] Select Company',
    props<{ companyId: string | null }>(),
);
