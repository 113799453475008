import { Injectable, Optional } from '@angular/core';
import {
    ActivatedRoute,
    ActivationStart,
    NavigationEnd,
    PRIMARY_OUTLET,
    Router,
    RoutesRecognized,
} from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { SiteFeature } from '@myrtls/api-interfaces';
import { Actions, createEffect, ofType, rootEffectsInit } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, from } from 'rxjs';
import {
    filter,
    first,
    map,
    mergeMap,
    switchMap,
    take,
    withLatestFrom,
} from 'rxjs/operators';
import { OnPremAuthService } from '../../core/modules/auth/on-prem-auth.service';
import { MetricDataFetchType } from '../../shared/models/metric-data-fetch-type.enum';
import { RoutePath } from '../../shared/models/route-path.enum';
import {
    getCompaniesRequestAction,
    selectCompanyAction,
} from '../companies/companies.actions';
import { $selectedCompanyId } from '../companies/companies.selectors';
import { AppState } from '../index';
import { getPermissionsRequestAction } from '../permissions/permissions.actions';
import {
    getSiteReportsRequestAction,
    getSiteAlertsRequestAction,
    getSiteDeviceCareConfigRequestAction,
    getSiteFeaturesRequestAction,
    getSiteMetricsConfigRequestAction,
    getSitesRequestAction,
    getSiteUsersRequestAction,
    selectSiteAction,
    updateSiteReportMetricsAction,
    getSitePlayerRequestAction,
    getPlayerOptionsRequestAction,
    startPoolingSitePlayerRequestAction,
    getSiteActivitiesRequestAction,
    getPhoneCallRequestAction,
    startPoolingSiteMetricsStatusAction,
    stopPoolingSiteMetricsStatusAction,
    getSiteDeviceCareInstallationRequestAction,
    getSiteSmtpStatusRequestAction,
    getVersionAction,
    stopPoolingSiteDeploymentBundlesAction,
} from '../sites/sites.actions';
import { SiteDetail } from '../sites/sites.reducer';
import { $selectedSiteId, $sites } from '../sites/sites.selectors';
import { updateRouteAction } from './route.actions';
import {
    isDateIntervalValid,
    stringDatesToDateRange,
} from '../../shared/utils/utils';
import { showSnackBarAction } from '../snack-bar/snack-bar.actions';
import { SnackBarType } from '../../shared/models/snack-bar-config.model';

type PathSegments = [RoutePath, string, SiteFeature];

@Injectable()
export class RouteEffects {
    user$;

    siteInit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rootEffectsInit),
            switchMap(() =>
                combineLatest([
                    this.user$,
                    this.router.events.pipe(
                        filter(
                            (event): event is ActivationStart =>
                                event instanceof ActivationStart,
                        ),
                    ),
                ]),
            ),
            first(([user, events]) => {
                return user || events.snapshot.queryParams.serverToken;
            }),
            mergeMap(([user]) => {
                const actions: Action[] = [
                    getCompaniesRequestAction(),
                    getSitesRequestAction(),
                    getVersionAction(),
                ];

                if (user !== undefined && user !== null) {
                    actions.push(getPermissionsRequestAction());
                }

                return actions;
            }),
        ),
    );

    updateRoute$ = createEffect(() =>
        this.router.events.pipe(
            filter((event): event is NavigationEnd => {
                return (
                    event instanceof NavigationEnd ||
                    event instanceof RoutesRecognized
                );
            }),
            map(event => {
                const urlTree = this.router.parseUrl(event.urlAfterRedirects);
                const [page, ...thirdToLast] = urlTree.root.children[
                    PRIMARY_OUTLET
                ].segments.map(({ path }) => path) as PathSegments;
                const hidePanels = this.route.firstChild?.snapshot?.data
                    ?.hidePanels as boolean | undefined;
                const start = event instanceof RoutesRecognized;

                switch (page) {
                    case RoutePath.SITES:
                        return updateRouteAction({
                            id: thirdToLast[0],
                            page,
                            hidePanels,
                            start,
                        });
                    case RoutePath.SITE:
                        return updateRouteAction({
                            id: thirdToLast[0],
                            page,
                            feature: thirdToLast[1],
                            queryParams: urlTree.queryParams,
                            hidePanels,
                            start,
                        });
                    default:
                        return updateRouteAction({
                            page,
                            hidePanels,
                            start,
                        });
                }
            }),
        ),
    );

    visitSitesView$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRouteAction),
            filter(
                ({ page, id, start }) =>
                    !start && page === RoutePath.SITES && id != null,
            ),
            withLatestFrom(this.store.pipe(select($selectedCompanyId))),
            filter(([, selectedCompanyId]) => selectedCompanyId == null),
            map(([{ id }]) => selectCompanyAction({ companyId: id || null })),
        ),
    );

    beforeVisitSiteView$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRouteAction),
            filter(({ page, id, start }) => {
                return start && page === RoutePath.SITE && id != null;
            }),
            switchMap(({ id, queryParams }) => {
                if (id != null) {
                    this.setSelectedIds(id);
                }

                const actions: Action[] = [
                    selectSiteAction({ siteId: id || null }),
                ];

                if (queryParams?.serverToken === undefined) {
                    actions.push(getSiteFeaturesRequestAction());
                    actions.push(getSiteSmtpStatusRequestAction());
                }

                return actions;
            }),
        ),
    );

    visitSiteUsersView$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRouteAction),
            filter(
                ({ page, id, feature, start }) =>
                    !start &&
                    page === RoutePath.SITE &&
                    id != null &&
                    feature === SiteFeature.SITE_USERS,
            ),
            map(() => getSiteUsersRequestAction()),
        ),
    );

    visitAdvancedAnalyticsView$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRouteAction),
            filter(
                ({ page, id, feature, start }) =>
                    !start &&
                    page === RoutePath.SITE &&
                    id != null &&
                    feature === SiteFeature.DEVICE_CARE_GRAFANA,
            ),
            map(() => getSiteDeviceCareConfigRequestAction({})),
        ),
    );

    visitSupportActivitiesView$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRouteAction),
            filter(
                ({ page, id, feature, start }) =>
                    !start &&
                    page === RoutePath.SITE &&
                    id !== null &&
                    feature === SiteFeature.SYSTEM_REVIEW,
            ),
            mergeMap(() => [getSiteActivitiesRequestAction()]),
        ),
    );

    // visitSiteDeployment$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(updateRouteAction),
    //         filter(
    //             ({ page, id, feature, start }) =>
    //                 !start &&
    //                 page === RoutePath.SITE &&
    //                 id !== null &&
    //                 feature === SiteFeature.SITE_DEPLOYMENT,
    //         ),
    //         mergeMap(() => [

    //         ]),
    //     ),
    // );

    visitPhoneCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRouteAction),
            filter(
                ({ page, id, feature, start }) =>
                    !start &&
                    page === RoutePath.SITE &&
                    id !== null &&
                    feature === SiteFeature.PHONE_CALL,
            ),
            mergeMap(() => [getPhoneCallRequestAction()]),
        ),
    );

    visitRtlsPlayerView$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRouteAction),
            filter(
                ({ page, id, feature, start }) =>
                    !start &&
                    page === RoutePath.SITE &&
                    id !== null &&
                    feature === SiteFeature.RTLS_PLAYER,
            ),
            mergeMap(() => [
                getPlayerOptionsRequestAction(),
                getSitePlayerRequestAction(),
                startPoolingSitePlayerRequestAction({ intervalTime: 60000 }),
            ]),
        ),
    );

    visitDeviceCareHistoryView$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRouteAction),
            filter(
                ({ page, id, feature, start }) =>
                    !start &&
                    page === RoutePath.SITE &&
                    id != null &&
                    feature === SiteFeature.DEVICE_CARE_HISTORY,
            ),
            mergeMap(({ queryParams }) => {
                const actions: Action[] = [
                    getSiteDeviceCareConfigRequestAction({
                        focus: queryParams?.focus,
                        period:
                            !queryParams ||
                            !queryParams.from ||
                            !queryParams.to ||
                            !isDateIntervalValid(
                                queryParams.from,
                                queryParams.to,
                            )
                                ? undefined
                                : {
                                      dateRange: stringDatesToDateRange(
                                          queryParams.from,
                                          queryParams.to,
                                      ),
                                      from: moment(queryParams.from as string)
                                          .startOf('day')
                                          .toDate(),
                                      to: moment(queryParams.to as string)
                                          .endOf('day')
                                          .toDate(),
                                  },
                    }),
                    getSiteAlertsRequestAction(),
                    getSiteMetricsConfigRequestAction({
                        dataFetch: MetricDataFetchType.HISTORY,
                    }),
                ];

                if (
                    queryParams &&
                    queryParams.from &&
                    queryParams.to &&
                    !isDateIntervalValid(queryParams.from, queryParams.to)
                ) {
                    actions.push(
                        showSnackBarAction({
                            config: {
                                type: SnackBarType.FAILED,
                                duration: 5000,
                                description: `Selected interval is not applicable.`,
                            },
                        }),
                    );
                }

                return actions;
            }),
        ),
    );

    visitDeviceCareReportView$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRouteAction),
            filter(
                ({ page, id, feature, queryParams, start }) =>
                    !start &&
                    page === RoutePath.SITE &&
                    id != null &&
                    feature === SiteFeature.DEVICE_CARE_REPORT &&
                    queryParams != null,
            ),
            mergeMap(({ id, queryParams }) => [
                updateSiteReportMetricsAction({
                    siteId: id || '',
                    metrics: queryParams?.['metrics'].split(','),
                }),
                getSiteDeviceCareConfigRequestAction({
                    period: {
                        dateRange: queryParams?.['dateRange'],
                        from: new Date(Number(queryParams?.['from']) * 1000),
                        to: new Date(Number(queryParams?.['to']) * 1000),
                    },
                }),
                getSiteMetricsConfigRequestAction({
                    dataFetch: MetricDataFetchType.ALL,
                }),
            ]),
        ),
    );

    visitDeviceCareSettingsView$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRouteAction),
            filter(
                ({ page, id, feature, start }) =>
                    !start &&
                    page === RoutePath.SITE &&
                    id != null &&
                    feature === SiteFeature.DEVICE_CARE_SETTINGS,
            ),
            mergeMap(() => [
                getSiteDeviceCareConfigRequestAction({}),
                getSiteMetricsConfigRequestAction({
                    dataFetch: MetricDataFetchType.NONE,
                }),
            ]),
        ),
    );

    visitDeviceCareInstallation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRouteAction),
            filter(
                ({ page, id, feature, start }) =>
                    !start &&
                    page === RoutePath.SITE &&
                    id != null &&
                    feature === SiteFeature.DEVICE_CARE_INSTALLATION,
            ),
            mergeMap(() => [
                getSiteDeviceCareInstallationRequestAction(),
                startPoolingSiteMetricsStatusAction({ intervalTime: 10000 }),
            ]),
        ),
    );

    leaveSiteDeployment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRouteAction),
            filter(
                ({ feature, start }) =>
                    !start && feature !== SiteFeature.SITE_DEPLOYMENT,
            ),
            mergeMap(() => [stopPoolingSiteDeploymentBundlesAction()]),
        ),
    );

    leaveDeviceCareInstallation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRouteAction),
            filter(
                ({ feature, start }) =>
                    !start && feature !== SiteFeature.DEVICE_CARE_INSTALLATION,
            ),
            mergeMap(() => [stopPoolingSiteMetricsStatusAction()]),
        ),
    );

    visitDeviceCareDashboardView$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRouteAction),
            filter(
                ({ page, id, feature, start }) =>
                    !start &&
                    page === RoutePath.SITE &&
                    id != null &&
                    feature === SiteFeature.DEVICE_CARE_DASHBOARD,
            ),
            mergeMap(() => [
                getSiteDeviceCareConfigRequestAction({}),
                getSiteAlertsRequestAction(),
                getSiteReportsRequestAction(),
                getSiteMetricsConfigRequestAction({
                    dataFetch: MetricDataFetchType.DASHBOARD,
                }),
            ]),
        ),
    );

    // on refresh, when site hasn't been manually selected
    // selects siteId from route and companyID from selected SiteDetail
    setSelectedIds(routeSiteId: string): void {
        this.store
            .select($selectedSiteId)
            .pipe(
                take(1),
                filter(siteId => siteId == null),
                switchMap(() =>
                    this.store.pipe(
                        select($sites),
                        map(sites => sites[routeSiteId]),
                        first(site => site && site.companyId != null),
                    ),
                ),
            )
            .subscribe((site: SiteDetail) => {
                this.store.dispatch(
                    selectCompanyAction({
                        companyId: site.companyId,
                    }),
                );
                this.store.dispatch(selectSiteAction({ siteId: site.id }));
            });
    }

    constructor(
        private readonly store: Store<AppState>,
        private readonly actions$: Actions,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        @Optional()
        private readonly cloudAuth: AuthService,
        @Optional() private readonly onPremAuth: OnPremAuthService,
    ) {
        if (this.cloudAuth) {
            this.user$ = cloudAuth.user$;
        } else if (this.onPremAuth) {
            this.user$ = from(onPremAuth.getUser());
        } else {
            throw Error('Missing AUTH provider.');
        }
    }
}
