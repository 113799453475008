import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AlertCalendarWidgetComponent } from './alert-calendar-widget.component';

@NgModule({
    declarations: [AlertCalendarWidgetComponent],
    imports: [CommonModule, MatButtonModule, MatIconModule],
    exports: [AlertCalendarWidgetComponent],
})
export class AlertCalendarWidgetModule {}
