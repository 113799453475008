import { Component } from '@angular/core';
import { InfluxdbService } from '../../core/services/influxdb.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import {
    $deviceCareConfig,
    $metricWithData,
} from '../../store/sites/sites.selectors';
import { filter, withLatestFrom } from 'rxjs';

@Component({
    selector: 'myrtls-tag-batteries-v2-modal',
    templateUrl: './tag-batteries-v2.modal.html',
    styleUrls: [],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class TagBatteriesV2Modal {
    metric = 'tag-batteries';
    lowBatteryTagsQuery = `import "dict"

thresholds = [
  "Piccolino": 2.33,
  "IMU": 3.77,
  "Leonardo iMU": 3.77,
  "Leonardo Asset": 2.33,
  "Leonardo Personal": 3.77,
  "Custom iMU": 3.77,
  "Custom Asset": 2.33,
  "Custom Personal": 3.77,
  "Li-ion": 3.77
]

from(bucket: "$BUCKET")
|> range(start: 0)
|> filter(fn: (r) => r["_measurement"] == "tag_batteries")
|> filter(fn: (r) => r["_field"] == "batteryVoltage")
|> tail(n: 9)
|> map(fn: (r) => ({
  r with _value:
    if r["_value"] < dict.get(dict: thresholds, key: r["platform"], default: 3.77) then 0.0
    else 1.0
}))
|> map(fn: (r) => ({
    r with name: if r.alias != "" then "[" + r.id + "] " + r.title + " (" + r.alias + ")"
        else "[" + r.id + "] " + r.title,
}))
|> median()
|> filter(fn: (r) => r["_value"] < 0.5)`;

    constructor(
        private store: Store<AppState>,
        private influxdbService: InfluxdbService,
    ) {
        this.store
            .select($deviceCareConfig)
            .pipe(
                filter(config => !!config),
                withLatestFrom(this.store.select($metricWithData(this.metric))),
            )
            .subscribe(async ([config, metric]) => {
                if (!config || !metric) return;

                console.log(config, metric);
                console.log(
                    await this.influxdbService.getDashboardChartData({
                        apiUrl: config.influx.host,
                        query: this.lowBatteryTagsQuery,
                        org: config.influx.organization,
                        token: config.influx.readToken,
                        bucket: config.influx.bucket,
                        overrides:
                            metric.overrides?.map(override => override.value) ||
                            [],
                    }),
                );
            });
    }
}
