<div class="report-wrapper" *ngIf="selectedPeriod$ | async as period">
    <ng-container *ngIf="getFilenameFromDateRange(period.dateRange, period.from, period.to) as reportDate">
        <div class="page-wrapper start-background flex flex-col p-14 justify-between">
            <img src="assets/logos/sewio-hid-logo-color-safe-zone-rgb.png" class="w-[200px]" />
            <div class="flex flex-col h-1/2 px-14">
                <div *ngIf="finished" id="report-title" class="text-brand font-medium text-[50px] mb-14">myRTLS Report</div>
                <div class="flex flex-col text-[20px]">
                    <div>{{ selectedSiteName$ | async }}</div>
                    <div>{{ selectedCompanyName$ | async }}</div>
                    <div>{{ reportDate }}</div>
                    <ng-container *ngIf="currentLicense$ | async as license">
                        <div *ngIf="isExpiring(license.expiration)" class="text-warning mt-7">Your myRTLS license will expire soon.</div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="page-break p-8 flex flex-col min-h-[100vh]">
            <div class="flex pb-4 mb-4 justify-between border-b border-dark-300">
                <div>System Overview</div>
                <div>{{ reportDate }}</div>
            </div>
            <div class="flex-grow">
                <myrtls-ratio-bar-widget [data]="stackedChartData$ | async" [showInfo]="false"></myrtls-ratio-bar-widget>
                <myrtls-single-stat-widget [data]="singleStatWidgetData$ | async" [showInfo]="false"></myrtls-single-stat-widget>
            </div>
            <div class="flex pt-4 mt-4 justify-between border-t border-dark-300">
                <div></div>
                <img src="assets/logos/sewio-hid-logo-color-safe-zone-rgb.png" class="w-[100px]" />
            </div>
        </div>
        <ng-container *ngIf="siteReportMetrics$ | async as reportMetrics">
            <ng-container *ngIf="chartOptions$ | async as metrics">
                <ng-container *ngFor="let metric of metrics | sortMetricsByIdsArray: reportMetrics; trackBy: metricById">
                    <div class="page-break p-8 min-h-[100vh] flex flex-col" *ngIf="metric && reportMetrics.includes(metric.id)">
                        <div class="flex pb-4 mb-4 justify-between border-b border-dark-300">
                            <div>{{ metric.name }}</div>
                            <div>{{ reportDate }}</div>
                        </div>
                        <div class="flex-grow">
                            <div *ngIf="metric.options as options" class="canvas-wrapper" echarts [options]="options" [style.height]="getChartHeight(metric.id, options)" (chartFinished)="onChartRendered(metric.id, reportMetrics)"></div>
                            <div #table class="table-container" *ngIf="metric.tableOptions as options" [attr.metricId]="metric.id" [attr.reportMetrics]="reportMetrics">
                                <table class="break-inside-auto" mat-table [dataSource]="options.rows">
                                    <ng-container *ngFor="let column of options.columns" [matColumnDef]="column.name">
                                        <th mat-header-cell *matHeaderCellDef>{{ column.alias }}</th>
                                        <td mat-cell *matCellDef="let element" [style.color]="element[column.name].color ? element[column.name].color : '#000'">
                                            <myrtls-state-timeline *ngIf="Array.isArray(element[column.name].value)" class="w-[500px]" [data]="element[column.name].value" [colorMapping]="element[column.name].colorMapping"></myrtls-state-timeline>
                                            <div *ngIf="!Array.isArray(element[column.name].value)">{{ element[column.name].value }}</div>
                                        </td>
                                    </ng-container>

                                    <tr class="no-data-row" *matNoDataRow>
                                        <td class="mat-cell no-data" colspan="9999">{{ options.noDataText || 'No data found.' }}</td>
                                    </tr>
                                    <tr mat-header-row *matHeaderRowDef="getColumns(options); sticky: false"></tr>
                                    <tr class="break-inside-avoid break-after-auto h-6 odd:bg-dark-50" mat-row *matRowDef="let row; columns: getColumns(options)"></tr>
                                </table>
                            </div>
                        </div>
                        <div class="flex pt-4 mt-4 justify-between border-t border-dark-300">
                            <div></div>
                            <img src="assets/logos/sewio-hid-logo-color-safe-zone-rgb.png" class="w-[100px]" />
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
        <div class="page-wrapper end-background flex flex-col p-14 justify-between">
            <img src="assets/logos/sewio-hid-logo-color-safe-zone-rgb.png" class="w-[200px]" />
            <div class="flex flex-col h-1/2 px-14">
                <div class="text-brand font-medium text-[50px] mb-14">Thank you<br />for using our product</div>
                <div class="flex flex-col text-[20px]">
                    <div>If you have any questions or feedback, please contact us at <a href="mailto:support@sewio.net">support&#64;sewio.net</a>.</div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
