import {
    animate,
    query,
    stagger,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteFeature } from '@myrtls/api-interfaces';
import { Store } from '@ngrx/store';
import { DashboardSingleStatValueModel } from '../../shared/models/dashboard-value.model';
import { StatusEnum } from '../../shared/models/status.enum';
import {
    INFO_MODAL_WIDTH,
    METRIC_MODAL_MAPPINGS,
} from '../../shared/utils/constants';
import { AppState } from '../../store';
import { setAlertsOnly } from '../../store/sites/sites.actions';

import { InfoModalComponent } from '../info-modal/info-modal.component';

@Component({
    selector: 'myrtls-single-stat-widget',
    templateUrl: './single-stat-widget.component.html',
    styleUrls: ['./single-stat-widget.component.scss'],
    animations: [
        trigger('listAnimation', [
            transition('* => *', [
                query(
                    ':enter',
                    [
                        style({ opacity: 0, transform: 'translateY(-10px)' }),
                        stagger(100, [
                            animate(
                                250,
                                style({ opacity: 1, transform: 'none' }),
                            ),
                        ]),
                    ],
                    { optional: true },
                ),
            ]),
        ]),
    ],
})
export class SingleStatWidgetComponent {
    readonly StatusEnum = StatusEnum;
    @Input() data: DashboardSingleStatValueModel[] | null = [];
    @Input() showInfo = true;

    constructor(
        private readonly store: Store<AppState>,
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    displayInfo($event: MouseEvent, info: string) {
        $event.preventDefault();
        $event.stopPropagation();

        this.dialog.open(InfoModalComponent, {
            width: INFO_MODAL_WIDTH,
            data: {
                content: info,
            },
        });
    }

    showInHistory(id?: string) {
        if (!id) {
            return;
        }

        this.store.dispatch(setAlertsOnly({ alertsOnly: false }));
        this.router.navigate([`../${SiteFeature.DEVICE_CARE_HISTORY}`], {
            relativeTo: this.route,
            queryParams: { focus: id },
        });
    }

    showPopup(id?: string, version?: string) {
        const popupComponent = METRIC_MODAL_MAPPINGS[`${id}:${version}`];

        if (popupComponent) {
            this.dialog.open(popupComponent, {
                width: INFO_MODAL_WIDTH,
            });
        }
    }

    trackByIndex(index: number) {
        return index;
    }
}
