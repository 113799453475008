import { Component } from '@angular/core';
import { InfluxdbService } from '../../core/services/influxdb.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import {
    $deviceCareConfig,
    $metricWithData,
} from '../../store/sites/sites.selectors';
import { filter, withLatestFrom } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';

interface TagAction {
    from: number;
    to: string;
    color?: string;
}

interface TagBattery {
    alias: string | number;
    title: string | number;
    platform: string | number;
    action?: string;
    color?: string;
}
@Component({
    selector: 'myrtls-tag-batteries-v2-modal',
    templateUrl: './tag-batteries-v2.modal.html',
    styleUrls: [],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class TagBatteriesV2Modal {
    metric = 'tag-batteries' as const;

    tagBatteries: TagBattery[] = [];
    dataSource = new MatTableDataSource<TagBattery>([]);
    tagActions: TagAction[] = [];
    columns = ['alias', 'title', 'platform', 'action'];
    loading = true;

    private lowBatteryTagsQuery = `import "dict"
import "strings"

replacableBatteryPlatforms = [
    "Piccolino",
    "Leonardo Asset",
    "Custom Asset",
]

rechargeableBatteryPlatforms = [
    "IMU",
    "Leonardo iMU",
    "Leonardo Personal",
    "Custom iMU",
    "Custom Personal",
    "Li-ion",
]

thresholds = [
  "Piccolino": 2.33,
  "IMU": 3.77,
  "Leonardo iMU": 3.77,
  "Leonardo Asset": 2.33,
  "Leonardo Personal": 3.77,
  "Custom iMU": 3.77,
  "Custom Asset": 2.33,
  "Custom Personal": 3.77,
  "Li-ion": 3.77
]

from(bucket: "$BUCKET")
|> range(start: 0)
|> filter(fn: (r) => r["_measurement"] == "tag_batteries")
|> filter(fn: (r) => r["_field"] == "batteryVoltage")
|> filter(fn: (r) => exists r["platform"])
|> group(columns: ["title"])
|> tail(n: 9)
|> map(fn: (r) => ({
  r with _value:
    if r["_value"] < dict.get(dict: thresholds, key: r["platform"], default: 3.77) then 0
    else 1
}))
|> median(method: "exact_selector")
|> map(fn: (r) => ({
    r with title: strings.joinStr(arr: ["0x", strings.toUpper(v: r.title)], v: "")
}))
|> filter(fn: (r) => not contains(value: r.title, set: $OVERRIDES))
|> map(fn: (r) => ({
    r with _value:
        if r["_value"] == 0 and contains(value: r["platform"], set: replacableBatteryPlatforms) then 0
        else if r["_value"] == 0 and contains(value: r["platform"], set: rechargeableBatteryPlatforms) then 1
        else 2
}))`;

    constructor(
        private store: Store<AppState>,
        private influxdbService: InfluxdbService,
    ) {
        this.store
            .select($deviceCareConfig)
            .pipe(
                filter(config => !!config),
                withLatestFrom(this.store.select($metricWithData(this.metric))),
            )
            .subscribe(async ([config, metric]) => {
                if (!config || !metric) return;

                this.tagBatteries = [];
                this.tagActions = [];

                const field = metric.historyTemplate?.fields[3];
                if (field && 'mappings' in field) {
                    const mapping = field.mappings;
                    if (typeof mapping !== 'string') {
                        mapping.forEach(action => {
                            const tagAction: TagAction = {
                                from: action.from,
                                to: action.to,
                                color: action.color,
                            };
                            this.tagActions.push(tagAction);
                        });
                    }
                }

                await this.influxdbService
                    .getDashboardChartData({
                        apiUrl: config.influx.host,
                        query: this.lowBatteryTagsQuery,
                        org: config.influx.organization,
                        token: config.influx.readToken,
                        bucket: config.influx.bucket,
                        overrides:
                            metric.overrides?.map(override => override.value) ||
                            [],
                    })
                    .then(data => {
                        data.forEach(tag => {
                            const tagBattery: TagBattery = {
                                alias: tag.alias || '',
                                title: tag.title || '',
                                platform: tag.platform || '3rd party / unknown',
                                action: this.tagActions.find(
                                    action => action.from === tag._value,
                                )?.to,
                                color: this.tagActions.find(
                                    action => action.from === tag._value,
                                )?.color,
                            };

                            if (tag._value !== undefined && tag._value < 2) {
                                this.tagBatteries.push(tagBattery);
                            }
                        });
                    });

                this.dataSource.data = [...this.tagBatteries];
                this.sortData({
                    active: 'action',
                    direction: 'desc',
                });
                this.loading = false;
            });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    sortData(sort: Sort) {
        const isAsc = sort.direction === 'asc';
        const key = sort.active as keyof TagBattery;

        this.dataSource.data = this.tagBatteries
            .sort((a, b) => {
                const aValue = String(a[key]);
                const bValue = String(b[key]);

                if (aValue == null && bValue == null) return 0;
                if (aValue == null) return isAsc ? -1 : 1;
                if (bValue == null) return isAsc ? 1 : -1;

                return this.compare(aValue, bValue, isAsc);
            })
            .slice();
    }

    private compare(
        a: string | boolean,
        b: string | boolean,
        isAsc: boolean,
    ): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}
