import { Component, Inject, ViewChild } from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store';
import {
    $metricFilteringData,
    $metricWithData,
} from '../../store/sites/sites.selectors';
import {
    getSiteMetricFilteringDataRequestAction,
    updateSiteMetricOverridesRequestAction,
} from '../../store/sites/sites.actions';
import { filter, withLatestFrom } from 'rxjs';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
    selector: 'myrtls-device-care-advanced-modal',
    templateUrl: './device-care-advanced.modal.html',
    styleUrls: [],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DeviceCareAdvancedModal {
    @ViewChild(MatSort) sort!: MatSort;

    readonly metricFilteringData$ = this.store.pipe(
        select($metricFilteringData(this.data.metric)),
        filter(x => !!x),
    );

    readonly metric$ = this.store.pipe(
        select($metricWithData(this.data.metric)),
    );

    dynamicColumns: string[] = [];
    staticColumns: string[] = [];
    columns: string[] = [];
    controls: AbstractControl[] | undefined;

    enabledFormGroup: UntypedFormArray | undefined;

    constructor(
        private readonly store: Store<AppState>,
        private readonly fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<string>,
        @Inject(MAT_DIALOG_DATA) public data: { metric: string },
    ) {
        this.store.dispatch(
            getSiteMetricFilteringDataRequestAction({
                metricId: this.data.metric,
            }),
        );

        this.store
            .pipe(select($metricWithData(this.data.metric)))
            .pipe(
                withLatestFrom(this.metricFilteringData$),
                filter(([metric]) => !!metric),
            )
            .subscribe(([metric, groups]) => {
                this.enabledFormGroup = this.fb.array(
                    groups?.map(item => {
                        const group = this.fb.group({
                            enabled:
                                metric?.overrides?.find(
                                    override => override.value === item.title,
                                ) === undefined,
                        });
                        Object.keys(item).forEach(key => {
                            if (!this.dynamicColumns.includes(key)) {
                                this.dynamicColumns.push(key);
                            }
                            group.addControl(key, this.fb.control(item[key]));
                        });
                        if (!this.staticColumns.includes('enabled')) {
                            this.staticColumns.push('enabled');
                        }
                        return group;
                    }) || [],
                );
                this.sortColumns(metric?.labelGroups);
                this.columns = [...this.dynamicColumns, ...this.staticColumns];
                this.controls = this.enabledFormGroup?.controls;
            });
    }

    save() {
        if (!this.enabledFormGroup?.valid) {
            return;
        }

        const overrides = this.enabledFormGroup
            .getRawValue()
            .filter(group => !group.enabled)
            .map(group => group.title as string);

        this.store.dispatch(
            updateSiteMetricOverridesRequestAction({
                metricId: this.data.metric,
                metricOverridesPutRequest: { overrides },
            }),
        );

        this.dialogRef.close({});
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value
            .trim()
            .toLowerCase();

        this.controls = this.enabledFormGroup?.controls.filter(control => {
            const values = control.value;
            return Object.values(values).some(value =>
                value?.toString().toLowerCase().includes(filterValue),
            );
        });
    }

    sortData(sort: Sort) {
        const isAsc = sort.direction === 'asc';
        const key = sort.active;

        this.controls = this.enabledFormGroup?.controls
            .sort((a, b) => {
                const aValue = a.value[key];
                const bValue = b.value[key];

                if (aValue == null && bValue == null) return 0;
                if (aValue == null) return isAsc ? -1 : 1;
                if (bValue == null) return isAsc ? 1 : -1;

                return this.compare(aValue, bValue, isAsc);
            })
            .slice();
    }

    private compare(
        a: string | boolean,
        b: string | boolean,
        isAsc: boolean,
    ): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    private sortColumns(order: string[] | undefined): void {
        if (!order) {
            return;
        }
        const orderSet = new Set(order);
        this.columns = [
            ...order.filter(item => this.columns.includes(item)),
            ...this.columns.filter(item => !orderSet.has(item)),
        ];
    }
}
