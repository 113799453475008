import { Component } from '@angular/core';
import { InfluxdbService } from '../../core/services/influxdb.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import {
    $deviceCareConfig,
    $metricWithData,
} from '../../store/sites/sites.selectors';
import { filter, withLatestFrom } from 'rxjs';

@Component({
    selector: 'myrtls-anchor-count-v2-modal',
    templateUrl: './anchor-count-v2.modal.html',
    styleUrls: [],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AnchorCountV2Modal {
    metric = 'anchor-count';
    offlineAnchorsQuery = `from(bucket: "$BUCKET")
    |> range(start: -2h)
    |> filter(fn: (r) => r._measurement == "online_anchors")
    |> filter(fn: (r) => r._field == "online")
    |> filter(fn: (r) => r._value == 0)
    |> filter(fn: (r) => not contains(value: r["title"], set: $OVERRIDES))
    |> group(columns: ["title"])
    |> last()`;

    anchorDetailsQuery = `from(bucket: "$BUCKET")
    |> range(start: -2h)
    |> filter(fn: (r) => r._measurement == "anchor_details")
    |> last()
    |> pivot(
       rowKey:["title"],
       columnKey: ["_field"],
       valueColumn: "_value"
    )
    |> group()
    |> drop(columns: ["_start", "_stop", "_measurement", "host", "url"])`;

    anchorsLastChangeQuery = `from(bucket: "$BUCKET")
    |> range(start: -1d)
    |> filter(fn: (r) => r._measurement == "online_anchors")
    |> filter(fn: (r) => r._field == "online")
    |> difference()
    |> filter(fn: (r) => r._value < 0)
    |> last()`;

    constructor(
        private store: Store<AppState>,
        private influxdbService: InfluxdbService,
    ) {
        this.store
            .select($deviceCareConfig)
            .pipe(
                filter(config => !!config),
                withLatestFrom(this.store.select($metricWithData(this.metric))),
            )
            .subscribe(async ([config, metric]) => {
                if (!config || !metric) return;

                console.log(config, metric);
                console.log(
                    await this.influxdbService.getDashboardChartData({
                        apiUrl: config.influx.host,
                        query: this.offlineAnchorsQuery,
                        org: config.influx.organization,
                        token: config.influx.readToken,
                        bucket: config.influx.bucket,
                        overrides:
                            metric.overrides?.map(override => override.value) ||
                            [],
                    }),
                );
                console.log(
                    await this.influxdbService.getDashboardChartData({
                        apiUrl: config.influx.host,
                        query: this.anchorDetailsQuery,
                        org: config.influx.organization,
                        token: config.influx.readToken,
                        bucket: config.influx.bucket,
                        overrides:
                            metric.overrides?.map(override => override.value) ||
                            [],
                    }),
                );

                // time of the last change can be found in (_time) property, if the anchor is offline and the value isn't there just show "longer than 24h"
                console.log(
                    await this.influxdbService.getDashboardChartData({
                        apiUrl: config.influx.host,
                        query: this.anchorsLastChangeQuery,
                        org: config.influx.organization,
                        token: config.influx.readToken,
                        bucket: config.influx.bucket,
                        overrides:
                            metric.overrides?.map(override => override.value) ||
                            [],
                    }),
                );
            });
    }
}
