<div class="alert-calendar-widget-container">
    <div class="alert-calendar-widget-header">
        <div class="alert-calendar-widget-header-title">Alert History</div>
        <div class="month-selector-container">
            <button mat-icon-button (click)="subtractMonth()">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <div class="month-selector-label">{{ monthSelectorLabel }}</div>
            <button mat-icon-button (click)="addMonth()" [disabled]="addMonthDisabled">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
        </div>
    </div>
    <div class="alert-calendars-wrapper">
        <div class="alert-calendar" *ngFor="let calendar of displayedCalendars">
            <div class="alert-calendar-week-row header-row">
                <div class="alert-calendar-day" *ngFor="let headerDay of dayHeaders">
                    {{ headerDay }}
                </div>
            </div>
            <div class="alert-calendar-date-wrapper">
                <div class="alert-calendar-week-row" *ngFor="let week of calendar.calendarArray">
                    <div
                        class="alert-calendar-day"
                        *ngFor="let day of week"
                        (click)="day.state !== undefined && displayAlerts(day.alerts, day.dateFormat)"
                        [ngClass]="{
                            hidden: day.hidden,
                            partial: day.partial,
                            empty: day.state === undefined,
                            undefined: day.state === State.UNDEFINED,
                            good: day.state === State.OK,
                            bad: day.state === State.ERROR,
                            warn: day.state === State.WARNING
                        }"
                    >
                        {{ day.dayString }}
                    </div>
                </div>
            </div>
            <div class="alert-calendar-month-name">
                {{ calendar.displayedMonth.format('MMMM') }}
            </div>
        </div>
    </div>
</div>
