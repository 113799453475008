import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { State } from '@myrtls/api-interfaces';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
    Small,
    VERY_LARGE_MODAL_WIDTH,
    XSmall,
} from '../../shared/utils/constants';
import { AppState } from '../../store';
import { setAlertsOnly } from '../../store/sites/sites.actions';
import { AlertsModalComponent } from '../alerts-modal/alerts-modal.component';
import {
    AlertIntervalWithMetricSettings,
    AlertsWithMetricSettings,
} from '../../shared/models/alerts.model';

const DAYS_IN_WEEK = 7;
const RANGE_LABEL_FORMAT = 'MMMM YYYY';

interface AlertCalendarDay {
    date: moment.Moment;
    dayString: string;
    dateFormat: string;
    alerts: AlertIntervalWithMetricSettings[];
    partial?: boolean;
    hidden?: boolean;
    state?: State;
}

@Component({
    selector: 'myrtls-alert-calendar-widget',
    templateUrl: './alert-calendar-widget.component.html',
    styleUrls: ['./alert-calendar-widget.component.scss'],
})
export class AlertCalendarWidgetComponent implements OnDestroy {
    private readonly unsubscribe$ = new Subject<void>();
    readonly dayHeaders = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
    readonly State = State;

    displayedCalendars: {
        displayedMonth: moment.Moment;
        calendarArray: AlertCalendarDay[][];
    }[] = [];
    monthSelectorLabel = '';
    addMonthDisabled = true;

    private numberOfDisplayedCalendars = 3;
    private selectedMonth: moment.Moment = moment().startOf('month');
    private storedAlertData: AlertsWithMetricSettings = {};

    @Input() set alertData(alerts: AlertsWithMetricSettings | null) {
        if (alerts != null) {
            this.storedAlertData = alerts;
            this.recreateData();
        }
    }

    constructor(
        private readonly store: Store<AppState>,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly cdRef: ChangeDetectorRef,
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.breakpointObserver
            .observe([XSmall, Small])
            .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
            .subscribe(state => {
                if (state.breakpoints[XSmall]) {
                    this.numberOfDisplayedCalendars = 1;
                } else if (state.breakpoints[Small]) {
                    this.numberOfDisplayedCalendars = 2;
                } else {
                    this.numberOfDisplayedCalendars = 3;
                }
                this.recreateData();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    displayAlerts(alerts: AlertIntervalWithMetricSettings[], date: string) {
        const dialogReference = this.dialog.open(AlertsModalComponent, {
            width: VERY_LARGE_MODAL_WIDTH,
            data: {
                date,
                alerts,
            },
        });

        dialogReference.afterClosed().subscribe(result => {
            if (result && result !== '') {
                this.store.dispatch(setAlertsOnly({ alertsOnly: true }));
                this.router.navigate(
                    [
                        ...this.route.snapshot.url
                            .map(url => url.path)
                            .slice(0, -1),
                        'device-care-history',
                    ],
                    {
                        queryParams: { from: result, to: result },
                    },
                );
            }
        });
    }

    addMonth(): void {
        if (this.addMonthDisabled) {
            return;
        }
        this.selectedMonth = moment(this.selectedMonth)
            .add(1, 'month')
            .startOf('month');
        this.recreateData();
    }

    subtractMonth(): void {
        this.selectedMonth = moment(this.selectedMonth)
            .subtract(1, 'month')
            .startOf('month');
        this.recreateData();
    }

    recreateData(): void {
        this.constructCalendarArray();
        this.constructMonthSelectorLabel();
        this.addMonthDisabled = moment(this.selectedMonth).isSameOrAfter(
            moment(),
            'months',
        );
        this.cdRef.markForCheck();
    }

    constructMonthSelectorLabel(): void {
        const previousMonthString =
            this.numberOfDisplayedCalendars > 1
                ? moment(this.selectedMonth)
                      .subtract(this.numberOfDisplayedCalendars - 1, 'months')
                      .format(RANGE_LABEL_FORMAT) + ' - '
                : '';
        const selectedMonthString =
            this.selectedMonth.format(RANGE_LABEL_FORMAT);
        this.monthSelectorLabel = `${previousMonthString}${selectedMonthString}`;
    }

    constructCalendarArray(): void {
        this.displayedCalendars = Array(this.numberOfDisplayedCalendars)
            .fill(0)
            .map((_, index) => {
                const displayedMonth = moment(this.selectedMonth).subtract(
                    index,
                    'month',
                );
                return {
                    displayedMonth,
                    calendarArray: this.constructCalendar(displayedMonth),
                };
            })
            .reverse();
    }

    /**
     * Fills [][] with Moment objects representing a month split into weeks
     * Also adds days from previous and next month to fill full weeks
     * @returns Moment[][]
     */
    constructCalendar(displayedMonth: moment.Moment): AlertCalendarDay[][] {
        const daysInPreviousMonth =
            (displayedMonth.day() + (DAYS_IN_WEEK - 1)) % DAYS_IN_WEEK;
        const firstDateOfCalendar = moment(displayedMonth).subtract(
            daysInPreviousMonth,
            'days',
        );
        const numberOfWeeks = Math.ceil(
            (displayedMonth.daysInMonth() + daysInPreviousMonth) / DAYS_IN_WEEK,
        );
        const emptyMonthCalendar: null[][] = Array(numberOfWeeks).fill(
            Array(DAYS_IN_WEEK).fill(''),
        );

        const days = emptyMonthCalendar.map((week, weekIndex) =>
            week.map((_, dayIndex) => {
                const date = moment(firstDateOfCalendar).add(
                    weekIndex * DAYS_IN_WEEK + dayIndex,
                    'days',
                );
                const hidden: boolean =
                    date.isBefore(displayedMonth) ||
                    date.isAfter(moment(displayedMonth).endOf('month'));

                const dateFormat = moment(date).format('YYYY-MM-DD');

                const state: State = this.storedAlertData[dateFormat]?.state;
                const partial: boolean =
                    this.storedAlertData[dateFormat]?.partial;

                const alerts: AlertIntervalWithMetricSettings[] =
                    this.storedAlertData[dateFormat]?.alerts || [];

                const dayString = date.format('DD');

                return {
                    date,
                    hidden,
                    partial,
                    alerts,
                    state,
                    dayString,
                    dateFormat,
                };
            }),
        );

        return days;
    }
}
