import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, OnDestroy, Optional } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
    DeviceCare,
    DeviceCarePutRequest,
    MetricPutRequest,
    ReportFrequency,
    FeatureState,
    SiteFeature,
} from '@myrtls/api-interfaces';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, startWith, take, takeUntil } from 'rxjs/operators';
import { SettingsMetricModel } from '../../shared/models/settings-data.model';
import { SnackBarType } from '../../shared/models/snack-bar-config.model';
import { EMAIL_REGEX, LARGE_MODAL_WIDTH } from '../../shared/utils/constants';
import { AppState } from '../../store';
import {
    clearDeviceCareRequestAction,
    createSiteTemplatesRequestAction,
    updateSiteMetricSettingsRequestAction,
    updateSiteReportSettingsRequestAction,
} from '../../store/sites/sites.actions';
import {
    $creatingTemplates,
    $metricsUpdateLoading,
    $reportUpdateLoading,
    $settingsData,
    $siteFeature,
} from '../../store/sites/sites.selectors';
import { showSnackBarAction } from '../../store/snack-bar/snack-bar.actions';
import { ClearDeviceCareModal } from '../clear-device-care-modal/clear-device-care.modal';
import { DeviceCareAdvancedModal } from '../device-care-advanced-modal/device-care-advanced.modal';
import { AuthService } from '@auth0/auth0-angular';

enum MetricsFormField {
    METRICS = 'metrics',
    ID = 'id',
    LABEL = 'label',
    ALERT = 'alert',
    REPORT = 'report',
    DASHBOARD = 'dashboard',
    HISTORY = 'history',
    THRESHOLD_VALUE = 'thresholdValue',
    THRESHOLD = 'threshold',
    THRESHOLD_STATE = 'thresholdState',
    THRESHOLD_EDITABLE = 'thresholdEditable',
    MAPPINGS = 'mappings',
    OVERRIDABLE = 'overridable',
    STATE = 'state',
}

enum ReportSettingsFormField {
    ALERT_EMAILS = 'alertEmails',
    REPORT_EMAILS = 'reportEmails',
    REPORT_FREQUENCY = 'reportFrequency',
}

@Component({
    selector: 'myrtls-device-care-settings',
    templateUrl: './device-care-settings.component.html',
    styleUrls: ['./device-care-settings.component.scss'],
})
export class DeviceCareSettingsComponent implements OnDestroy {
    private readonly unsubscribe$ = new Subject<void>();
    readonly MetricsFormField = MetricsFormField;
    readonly ReportSettingsFormField = ReportSettingsFormField;
    readonly ReportFrequency = ReportFrequency;

    readonly separatorKeysCodes = [ENTER, COMMA, SPACE] as const;

    metricsFormGroup: UntypedFormGroup | undefined;
    reportSettingsFormGroup: UntypedFormGroup | undefined;

    metricsColumns = ['metric', 'alert', 'report', 'threshold', 'advanced'];

    metricsSaveButtonDisabled$: Observable<boolean> = of(true);
    reportSaveButtonDisabled$: Observable<boolean> = of(true);

    advancedAnalyticsFeature$ = this.store.select(
        $siteFeature(SiteFeature.DEVICE_CARE_GRAFANA),
    );

    creatingTemplates$ = this.store.select($creatingTemplates);

    grafanaFeature$ = this.store.select(
        $siteFeature(SiteFeature.DEVICE_CARE_GRAFANA),
    );

    get metricsFormArray(): UntypedFormArray | undefined {
        return this.metricsFormGroup?.controls[MetricsFormField.METRICS] as
            | UntypedFormArray
            | undefined;
    }

    get metricsFormArrayFormGroups(): UntypedFormGroup[] | undefined {
        return this.metricsFormArray?.controls as
            | UntypedFormGroup[]
            | undefined;
    }

    FeatureState = FeatureState;

    validateEmail(email: string): boolean {
        return EMAIL_REGEX.test(String(email).toLowerCase());
    }

    constructor(
        @Optional()
        private readonly cloudAuth: AuthService,
        private readonly store: Store<AppState>,
        private readonly fb: UntypedFormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        public dialog: MatDialog,
    ) {
        if (this.cloudAuth) {
            this.cloudAuth.user$?.pipe(take(1)).subscribe(user => {
                if (user?.email?.endsWith('@sewio.net')) {
                    this.metricsColumns.splice(1, 0, 'dashboard', 'history');
                }
            });
        }

        this.store
            .pipe(
                select($settingsData),
                filter(
                    ({ metrics, deviceCare }) =>
                        metrics?.length > 0 && deviceCare != undefined,
                ),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(({ metrics, deviceCare }) => {
                if (this.metricsFormGroup) {
                    this.patchMetricsForm(metrics);
                } else {
                    this.metricsFormGroup = this.createMetricsForm(metrics);
                }

                if (this.reportSettingsFormGroup) {
                    this.patchReportSettingsForm(deviceCare as DeviceCare);
                } else {
                    this.reportSettingsFormGroup =
                        this.createReportSettingsForm(deviceCare as DeviceCare);
                }

                this.metricsSaveButtonDisabled$ = combineLatest([
                    this.store.select($metricsUpdateLoading),
                    this.metricsFormGroup.statusChanges.pipe(map(() => false)),
                ]).pipe(
                    map(([loading, noChanges]) => loading || noChanges),
                    startWith(true),
                );

                this.reportSaveButtonDisabled$ = combineLatest([
                    this.store.select($reportUpdateLoading),
                    this.reportSettingsFormGroup.statusChanges.pipe(
                        map(() => false),
                    ),
                ]).pipe(
                    map(([loading, noChanges]) => loading || noChanges),
                    startWith(true),
                );
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    createMetricsForm(metrics: SettingsMetricModel[]): UntypedFormGroup {
        return this.fb.group({
            [MetricsFormField.METRICS]: this.fb.array(
                metrics.map(item =>
                    this.fb.group({
                        [MetricsFormField.ID]: item.id,
                        [MetricsFormField.LABEL]: [item.label],
                        [MetricsFormField.ALERT]: [
                            {
                                value: item.alert,
                                disabled: !item.hasAlertTemplate,
                            },
                        ],
                        [MetricsFormField.REPORT]: [
                            {
                                value: item.report,
                                disabled: !item.hasHistoryTemplate,
                            },
                        ],
                        [MetricsFormField.DASHBOARD]: [
                            {
                                value: item.dashboard,
                                disabled: !item.hasDashboardTemplate,
                            },
                        ],
                        [MetricsFormField.HISTORY]: [
                            {
                                value: item.history,
                                disabled: !item.hasHistoryTemplate,
                            },
                        ],
                        [MetricsFormField.THRESHOLD_VALUE]: [
                            item.threshold?.value,
                            Validators.required,
                        ],
                        [MetricsFormField.THRESHOLD]: [item.threshold],
                        [MetricsFormField.THRESHOLD_STATE]: [
                            item.threshold?.state,
                        ],
                        [MetricsFormField.THRESHOLD_EDITABLE]: [
                            item.threshold?.editable,
                        ],
                        [MetricsFormField.MAPPINGS]: [item.mappings],
                        [MetricsFormField.OVERRIDABLE]: [item.overridable],
                        [MetricsFormField.STATE]: [item.state],
                    }),
                ),
            ),
        });
    }

    patchMetricsForm(metrics: SettingsMetricModel[]): void {
        this.metricsFormArrayFormGroups?.forEach(formGroup => {
            const metric = metrics.find(
                m => m.id === formGroup.get(MetricsFormField.ID)?.value,
            );

            if (metric != null) {
                formGroup.patchValue({
                    [MetricsFormField.ALERT]: metric.alert,
                    [MetricsFormField.REPORT]: metric.report,
                    [MetricsFormField.DASHBOARD]: metric.dashboard,
                    [MetricsFormField.HISTORY]: metric.history,
                    [MetricsFormField.THRESHOLD_VALUE]: metric.threshold?.value,
                    [MetricsFormField.THRESHOLD]: metric.threshold,
                });
            }
        });
    }

    createReportSettingsForm(deviceCare: DeviceCare): UntypedFormGroup {
        return this.fb.group({
            [ReportSettingsFormField.ALERT_EMAILS]: [
                deviceCare?.alertRecipients || [],
            ],
            [ReportSettingsFormField.REPORT_EMAILS]: [
                deviceCare?.reportRecipients || [],
            ],
            [ReportSettingsFormField.REPORT_FREQUENCY]: [
                deviceCare?.reportFrequency || ReportFrequency.MONTH,
                Validators.required,
            ],
        });
    }

    patchReportSettingsForm(deviceCare: DeviceCare): void {
        this.reportSettingsFormGroup?.patchValue({
            [ReportSettingsFormField.ALERT_EMAILS]:
                deviceCare?.alertRecipients || [],
            [ReportSettingsFormField.REPORT_EMAILS]:
                deviceCare?.reportRecipients || [],
            [ReportSettingsFormField.REPORT_FREQUENCY]:
                deviceCare?.reportFrequency || ReportFrequency.MONTH,
        });
    }

    showCopyToClipboardMessage(label: string): void {
        this.store.dispatch(
            showSnackBarAction({
                config: {
                    type: SnackBarType.SUCCESSFUL,
                    duration: 1000,
                    description: `Value of "${label}" has been copied to clipboard`,
                },
            }),
        );
    }

    getGroupCheckboxState(field: MetricsFormField): boolean {
        return !this.metricsFormArray?.controls.some(
            control => control?.get(field)?.value === false,
        );
    }

    changeGroupCheckboxState(
        change: MatCheckboxChange,
        field: MetricsFormField,
    ): void {
        this.metricsFormArray?.controls.forEach(control => {
            const controlField = control.get(field);

            if (controlField && !controlField.disabled) {
                controlField.patchValue(change.checked);
            }
        });

        this.metricsFormArray?.markAsTouched();
    }

    addEmail(event: MatChipInputEvent, field: ReportSettingsFormField): void {
        if (this.validateEmail(event.value)) {
            const value = this.reportSettingsFormGroup?.get(field)?.value;
            this.reportSettingsFormGroup?.controls[field].patchValue([
                ...value,
                event.value,
            ]);
            event.input.value = '';
            this.reportSettingsFormGroup?.markAsTouched();
        }
    }

    removeEmail(email: string, field: ReportSettingsFormField): void {
        const value = this.reportSettingsFormGroup?.get(field)?.value;
        this.reportSettingsFormGroup?.controls[field].patchValue(
            value.filter((v: string) => v !== email),
        );
        this.reportSettingsFormGroup?.markAsTouched();
    }

    saveMetricsSettings(): void {
        if (this.metricsFormGroup?.invalid) {
            return;
        }

        const metricPutRequest: MetricPutRequest[] = (
            this.metricsFormArrayFormGroups || []
        )
            .map(metricFormGroup => metricFormGroup?.value)
            .map(item => ({
                id: item[MetricsFormField.ID],
                alert: item[MetricsFormField.ALERT],
                report: item[MetricsFormField.REPORT],
                dashboard: item[MetricsFormField.DASHBOARD],
                history: item[MetricsFormField.HISTORY],
                thresholdValue:
                    item[MetricsFormField.THRESHOLD_VALUE] != null
                        ? item[MetricsFormField.THRESHOLD_VALUE] *
                          (item[MetricsFormField.THRESHOLD]
                              ?.valueAbbreviation || 1)
                        : undefined,
            }));

        this.store.dispatch(
            updateSiteMetricSettingsRequestAction({ metricPutRequest }),
        );
    }

    saveReportingSettings(): void {
        if (this.reportSettingsFormGroup?.invalid) {
            return;
        }
        const deviceCarePutRequest: DeviceCarePutRequest = {
            reportFrequency: this.reportSettingsFormGroup?.get(
                ReportSettingsFormField.REPORT_FREQUENCY,
            )?.value as ReportFrequency,
            alertRecipients: this.reportSettingsFormGroup?.get(
                ReportSettingsFormField.ALERT_EMAILS,
            )?.value,
            reportRecipients: this.reportSettingsFormGroup?.get(
                ReportSettingsFormField.REPORT_EMAILS,
            )?.value,
        };

        this.store.dispatch(
            updateSiteReportSettingsRequestAction({ deviceCarePutRequest }),
        );
    }

    createTemplates() {
        this.store.dispatch(createSiteTemplatesRequestAction());
    }

    restartInstallation() {
        this.router.navigate([`../${SiteFeature.DEVICE_CARE_INSTALLATION}`], {
            relativeTo: this.route,
        });
    }

    clearData() {
        const dialogReference = this.dialog.open(ClearDeviceCareModal);

        dialogReference
            .afterClosed()
            .pipe(take(1))
            .subscribe(result => {
                if (result && result !== '') {
                    this.store.dispatch(
                        clearDeviceCareRequestAction({
                            clearDeviceCareRequest: result,
                        }),
                    );
                }
            });
    }

    openAdvancedSettings(metric: string) {
        const dialogReference = this.dialog.open(DeviceCareAdvancedModal, {
            width: LARGE_MODAL_WIDTH,
            disableClose: true,
            data: {
                metric,
            },
        });

        dialogReference
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
                // do nothing
            });
    }
}
