import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { SiteFeature } from '@myrtls/api-interfaces';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { AppState } from '../../store';
import {
    $companies,
    $companiesLoaded,
} from '../../store/companies/companies.selectors';
import { $sitesList, $sitesLoaded } from '../../store/sites/sites.selectors';
import { showSnackBarAction } from '../../store/snack-bar/snack-bar.actions';
import { RoutePath } from '../models/route-path.enum';
import { SnackBarType } from '../models/snack-bar-config.model';

@Injectable({ providedIn: 'root' })
export class RedirectGuard  {
    constructor(
        private readonly router: Router,
        private readonly store: Store<AppState>,
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean | Observable<boolean> {
        if (state.url.startsWith(`/${RoutePath.COMPANIES}`)) {
            return this.store.pipe(
                select($companiesLoaded),
                first(loaded => loaded),
                switchMap(() => this.store.pipe(select($companies))),
                map(companies => {
                    if (companies.length === 0) {
                        this.store.dispatch(
                            showSnackBarAction({
                                config: {
                                    type: SnackBarType.FAILED,
                                    description: `This user doesn't have access to any companies`,
                                    actionLabel: 'Dismiss',
                                },
                            }),
                        );
                        return true;
                    }
                    if (companies.length === 1) {
                        this.router.navigate([
                            RoutePath.SITES,
                            companies[0].id,
                        ]);
                        return false;
                    }
                    return true;
                }),
            );
        }

        if (state.url.startsWith(`/${RoutePath.SITES}`)) {
            return this.store.pipe(
                select($sitesLoaded),
                first(loaded => loaded),
                switchMap(() => this.store.pipe(select($sitesList))),
                switchMap(sites => {
                    if (sites.length === 1) {
                        return this.store.pipe(
                            select($companiesLoaded),
                            first(loaded => loaded),
                            switchMap(() =>
                                this.store.pipe(select($companies)),
                            ),
                            map(companies => {
                                if (companies.length === 1) {
                                    this.router.navigate([
                                        RoutePath.SITE,
                                        sites[0].id,
                                        SiteFeature.DEVICE_CARE_DASHBOARD,
                                    ]);
                                    return false;
                                }
                                return true;
                            }),
                        );
                    }

                    return of(true);
                }),
            );
        }

        return true;
    }
}
