<div class="flex flex-col p-5 container">
    <div class="buttons-line">
        <ng-container *ngIf="grafanaFeature$ | async as grafanaFeature">
            <button mat-button color="primary" [disabled]="grafanaFeature.state !== 'enabled' || (creatingTemplates$ | async)" (click)="createTemplates()">
                <mat-progress-spinner *ngIf="creatingTemplates$ | async" class="button-spinner" [diameter]="15" mode="indeterminate"></mat-progress-spinner>Create Advanced Analytics Templates
            </button>
        </ng-container>
        <button mat-button color="primary" (click)="restartInstallation()">Restart Device Care Installation</button>
        <button mat-button color="primary" (click)="clearData()">Delete All Device Care Data</button>
    </div>
    <div class="header-title">Metric Settings</div>
    <mat-card class="settings-card" *ngIf="metricsFormGroup && metricsFormArrayFormGroups" [formGroup]="metricsFormGroup">
        <mat-card-content class="settings-card-content metrics-card-content">
            <table mat-table [dataSource]="metricsFormArrayFormGroups" multiTemplateDataRows>
                <ng-container matColumnDef="metric">
                    <th mat-header-cell *matHeaderCellDef class="border-b border-dark-500">Metric</th>
                    <td mat-cell *matCellDef="let element" class="gap-2">
                        {{ element.get(MetricsFormField.LABEL)?.value }}
                        @switch (element.get(MetricsFormField.STATE)?.value) {
                            @case ('beta') {
                                <myrtls-chip [text]="'beta'" [type]="'info'" class="inline-block ml-2"></myrtls-chip>
                            }
                            @case ('deprecating') {
                                <myrtls-chip [text]="'deprecating'" [type]="'default'" class="inline-block ml-2"></myrtls-chip>
                            }
                        }
                    </td>
                    <td mat-footer-cell *matFooterCellDef class="font-semibold flex h-[74px] items-center">All Metrics</td>
                </ng-container>

                <ng-container matColumnDef="dashboard">
                    <th mat-header-cell *matHeaderCellDef class="border-b border-dark-500">Show on Dashboard</th>
                    <td mat-cell *matCellDef="let element" [formGroup]="element">
                        <mat-checkbox color="primary" [indeterminate]="element.get(MetricsFormField.DASHBOARD)?.disabled" [formControlName]="MetricsFormField.DASHBOARD"></mat-checkbox>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <mat-checkbox color="primary" [checked]="getGroupCheckboxState(MetricsFormField.DASHBOARD)" (change)="changeGroupCheckboxState($event, MetricsFormField.DASHBOARD)"></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="history">
                    <th mat-header-cell *matHeaderCellDef class="border-b border-dark-500">Show in History</th>
                    <td mat-cell *matCellDef="let element" [formGroup]="element">
                        <mat-checkbox color="primary" [indeterminate]="element.get(MetricsFormField.HISTORY)?.disabled" [formControlName]="MetricsFormField.HISTORY"></mat-checkbox>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <mat-checkbox color="primary" [checked]="getGroupCheckboxState(MetricsFormField.HISTORY)" (change)="changeGroupCheckboxState($event, MetricsFormField.HISTORY)"></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="alert">
                    <th mat-header-cell *matHeaderCellDef class="border-b border-dark-500">Send Alert via Email</th>
                    <td mat-cell *matCellDef="let element" [formGroup]="element">
                        <mat-checkbox color="primary" [indeterminate]="element.get(MetricsFormField.ALERT)?.disabled" [formControlName]="MetricsFormField.ALERT"></mat-checkbox>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <mat-checkbox color="primary" [checked]="getGroupCheckboxState(MetricsFormField.ALERT)" (change)="changeGroupCheckboxState($event, MetricsFormField.ALERT)"></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="report">
                    <th mat-header-cell *matHeaderCellDef class="border-b border-dark-500">Include in Report</th>
                    <td mat-cell *matCellDef="let element" [formGroup]="element">
                        <mat-checkbox color="primary" [indeterminate]="element.get(MetricsFormField.REPORT)?.disabled" [formControlName]="MetricsFormField.REPORT"></mat-checkbox>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <mat-checkbox color="primary" [checked]="getGroupCheckboxState(MetricsFormField.REPORT)" (change)="changeGroupCheckboxState($event, MetricsFormField.REPORT)"></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="threshold">
                    <th mat-header-cell *matHeaderCellDef class="border-b border-dark-500">Threshold</th>
                    <td mat-cell *matCellDef="let element" [formGroup]="element">
                        @if (element.get(MetricsFormField.THRESHOLD)?.value; as threshold) {
                            @if (threshold.editable) {
                                @if (element.get(MetricsFormField.THRESHOLD_STATE)?.value !== true) {
                                    <mat-form-field class="min-w-28 max-w-40 my-2">
                                        <input matInput type="number" [formControlName]="MetricsFormField.THRESHOLD_VALUE" />
                                        <span matPrefix class="mx-2">{{ threshold.symbol }}&nbsp;</span>
                                        <span matSuffix class="mx-2">{{ threshold.unit }}</span>
                                    </mat-form-field>
                                } @else {
                                    <mat-form-field class="min-w-28 max-w-40 my-2">
                                        <mat-label>Alerting State</mat-label>
                                        <mat-select [formControlName]="MetricsFormField.THRESHOLD_VALUE">
                                            <mat-option *ngFor="let mapping of element.get(MetricsFormField.MAPPINGS)?.value || [{ from: 0, to: 'None' }]" [value]="mapping.from">{{ mapping.to }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                }
                            } @else {
                                <div class="flex h-[74px] items-center text-dark-400">None</div>
                            }
                        }
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <ng-container matColumnDef="advanced">
                    <th mat-header-cell *matHeaderCellDef class="border-b border-dark-500">Advanced</th>
                    <td mat-cell *matCellDef="let element" [formGroup]="element">
                        @if (element.get(MetricsFormField.OVERRIDABLE)?.value) {
                            <button mat-icon-button class="my-2" (click)="openAdvancedSettings(element.get(MetricsFormField.ID)?.value)">
                                <mat-icon>tune</mat-icon>
                            </button>
                        }
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="metricsColumns" class="font-semibold"></tr>
                <tr mat-row *matRowDef="let element; columns: metricsColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="metricsColumns" class="border-t border-dark-500"></tr>
            </table>

            <div class="metrics-input-section metrics-action-buttons mat-card-footer">
                <button class="metrics-action-button" mat-button color="primary" type="button" (click)="saveMetricsSettings()" [disabled]="metricsSaveButtonDisabled$ | async">Save</button>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="header-title">Notification Settings</div>
    <mat-card class="settings-card" *ngIf="reportSettingsFormGroup" [formGroup]="reportSettingsFormGroup">
        <mat-card-content class="settings-card-content metrics-card-content">
            <div class="w-full flex flex-col gap-4">
                <mat-form-field>
                    <mat-label>Emails for receiving alerts</mat-label>
                    <mat-chip-grid #alertEmailsChipList aria-label="Enter recipient emails">
                        <mat-chip-row *ngFor="let reportEmail of reportSettingsFormGroup.get(ReportSettingsFormField.ALERT_EMAILS)?.value" (removed)="removeEmail(reportEmail, ReportSettingsFormField.ALERT_EMAILS)">
                            {{ reportEmail }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-row>
                        <input placeholder="Add email..." [matChipInputFor]="alertEmailsChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addEmail($event, ReportSettingsFormField.ALERT_EMAILS)" />
                    </mat-chip-grid>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Emails for receiving reports</mat-label>
                    <mat-chip-grid #reportEmailsChipList>
                        <mat-chip-row *ngFor="let reportEmail of reportSettingsFormGroup.get(ReportSettingsFormField.REPORT_EMAILS)?.value" (removed)="removeEmail(reportEmail, ReportSettingsFormField.REPORT_EMAILS)">
                            {{ reportEmail }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-row>
                        <input placeholder="Add email..." [matChipInputFor]="reportEmailsChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addEmail($event, ReportSettingsFormField.REPORT_EMAILS)" />
                    </mat-chip-grid>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Reporting Frequency</mat-label>
                    <mat-select [formControlName]="ReportSettingsFormField.REPORT_FREQUENCY">
                        <mat-option [value]="ReportFrequency.WEEK"> Weekly </mat-option>
                        <mat-option [value]="ReportFrequency.MONTH"> Monthly </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- Action buttons -->
            <div class="metrics-input-section metrics-action-buttons mat-card-footer">
                <button class="metrics-action-button" mat-button color="primary" type="button" (click)="saveReportingSettings()" [disabled]="reportSaveButtonDisabled$ | async">Save</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>
