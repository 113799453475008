import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoutePath } from '../../shared/models/route-path.enum';
import { AppState } from '../../store';
import { selectCompanyAction } from '../../store/companies/companies.actions';
import {
    $companies,
    $companiesLoading,
} from '../../store/companies/companies.selectors';

@Component({
    selector: 'myrtls-dashboard-companies',
    templateUrl: './companies.view.html',
    styleUrls: ['./companies.view.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CompaniesView {
    private readonly filterValueSubject$ = new BehaviorSubject<string>('');
    readonly companiesLoading$ = this.store.pipe(select($companiesLoading));
    readonly companies$ = combineLatest([
        this.store.pipe(select($companies)),
        this.filterValueSubject$.asObservable(),
    ]).pipe(
        map(([companies, filterValue]) =>
            companies
                .filter(company => company?.name != null)
                .filter(({ name }) => name.toLowerCase().includes(filterValue)),
        ),
    );

    constructor(
        private readonly store: Store<AppState>,
        private readonly router: Router,
    ) {}

    selectCompany(companyId: string): void {
        this.store.dispatch(selectCompanyAction({ companyId }));
        this.router.navigate([RoutePath.SITES, companyId]);
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.filterValueSubject$.next(filterValue.trim().toLowerCase());
    }
}
