import {
    AlertInterval,
    ChartType,
    DeviceCare,
    Feature,
    FormatType,
    MappingFunction,
    MetricField,
    MetricMapping,
    MetricParameters,
    MetricThresholdProperties,
    Report,
    State,
    ThresholdType,
    User,
} from '@myrtls/api-interfaces';
import { createSelector } from '@ngrx/store';
import { CustomSeriesOption, EChartsOption } from 'echarts';
import { DataZoomComponentOption } from 'echarts/types/dist/echarts';

import {
    XAXisOption,
    YAXisOption,
} from 'echarts/types/src/coord/cartesian/AxisModel';
import { CallbackDataParams } from 'echarts/types/src/util/types';
import * as moment from 'moment';
import {
    DashboardRatioBarValueModel,
    DashboardSingleStatValueModel,
} from '../../shared/models/dashboard-value.model';
import { MetricGroup } from '../../shared/models/metric-group.enum';
import { MetricWithData } from '../../shared/models/metric-with-data.model';
import {
    SettingsDataModel,
    SettingsMetricModel,
    ThresholdToSymbolMap,
} from '../../shared/models/settings-data.model';
import { StatusEnum } from '../../shared/models/status.enum';
import {
    THRESHOLD_MARK_AREA_COLOR,
    X_AXIS_LABEL_FORMAT,
} from '../../shared/utils/constants';
import {
    getFieldAlias,
    tooltipFormatter,
    valueFormatter,
} from '../../shared/utils/utils';
import { $selectedCompanyId } from '../companies/companies.selectors';
import { AppState } from '../index';
import { SiteDetail, SitesState } from './sites.reducer';
import { MetricFilteringDataFragment } from '../../shared/models/metric-data-fragment.model';
import { AlertsWithMetricSettings } from '../../shared/models/alerts.model';

export interface TableOptions {
    columns: { name: string; alias: string }[];
    rows: {
        [key: string]: {
            value: string | { value: number | null; at: string }[];
            color?: string;
        };
    }[];
    noDataText?: string;
}

const $sitesState = (state: AppState): SitesState => state.sites;

export const $sitesLoading = createSelector(
    $sitesState,
    ({ loading }): boolean => loading,
);

export const $sitesLoaded = createSelector(
    $sitesState,
    ({ loaded }): boolean => loaded,
);

export const $sites = createSelector(
    $sitesState,
    ({ sites }): { [siteId: string]: SiteDetail } => sites,
);

export const $currentAlerts = createSelector(
    $sitesState,
    sites => sites.currentAlerts,
);

export const $downloadingDeployment = createSelector(
    $sitesState,
    sites => sites.downloadingDeployment,
);

export const $creatingTemplates = createSelector(
    $sitesState,
    sites => sites.creatingTemplates,
);

export const $sitesFocus = createSelector($sitesState, ({ focus }) => focus);
export const $sitePlayer = createSelector($sitesState, site => site.player);
export const $siteDeployments = createSelector(
    $sitesState,
    site => site.deploymentBundles,
);

export const $siteReleases = createSelector($sitesState, site => site.releases);
export const $siteReleasesLoading = createSelector(
    $sitesState,
    site => site.releasesLoading,
);

export const $version = createSelector($sitesState, site => site.version);

export const $siteActivities = createSelector(
    $sitesState,
    site => site.activities,
);

export const $playerOptions = createSelector(
    $sitesState,
    site => site.playerOptions,
);

export const $smtpStatus = createSelector($sitesState, site => site.smtpStatus);

export const $allSitesList = createSelector(
    $sitesState,
    (state): SiteDetail[] =>
        Object.keys(state.sites).reduce(
            (acc: SiteDetail[], key) => [...acc, state.sites[key]],
            [],
        ),
);

export const $sitesList = createSelector(
    $allSitesList,
    $selectedCompanyId,
    (sites, companyId): SiteDetail[] =>
        (sites || []).filter(site => site.companyId === companyId),
);

export const $selectedSiteId = createSelector(
    $sitesState,
    ({ selectedSiteId }): string | null => selectedSiteId,
);

export const $site = createSelector(
    $sites,
    $selectedSiteId,
    (sites, id): SiteDetail | null => (id && sites[id]) || null,
);

export const $nextReview = createSelector(
    $site,
    site => site?.nextReviewDate || null,
);

export const $siteUsers = createSelector(
    $site,
    (site): User[] => site?.users || [],
);

export const $selectedSite = createSelector(
    $sites,
    $selectedSiteId,
    (sites, selectedSiteId: string | null): SiteDetail | null =>
        (selectedSiteId && sites[selectedSiteId]) || null,
);

export const $currentLicense = createSelector(
    $selectedSite,
    site => site?.license || null,
);

export const $selectedSiteName = createSelector(
    $selectedSite,
    (site: SiteDetail | null): string =>
        site ? 'S' + site.id + ' - ' + site.name : 'None',
);

export const $siteFeatures = createSelector(
    $selectedSite,
    (site): Feature[] => site?.features || [],
);

export const $selectedSiteLicenseRaw = createSelector(
    $selectedSite,
    site => site?.licenseRaw || null,
);

export const $siteFeature = (feature: string) =>
    createSelector($selectedSite, (site): Feature | null => {
        return (
            site?.features?.find(
                foundFeature => foundFeature.name === feature,
            ) || null
        );
    });

export const $sitePhoneCall = createSelector(
    $sitesState,
    site => site.phoneCall,
);

export const $siteReportMetrics = createSelector(
    $selectedSite,
    site => site?.reportMetrics,
);

export const $metricNames = createSelector($selectedSite, site => {
    return site?.metrics?.map(metric => ({ id: metric.id, name: metric.name }));
});

export const $metricsWithData = createSelector(
    $selectedSite,
    (site): MetricWithData[] => site?.metrics || [],
);

export const $alertsOnly = createSelector(
    $sitesState,
    state => state.alertsOnly,
);

export const $isReport = createSelector($sitesState, state => state.isReport);

export const $metricsStatus = createSelector(
    $sitesState,
    state => state.metricsStatus,
);

export const $chartOptions = (showSliders = true, ignoreAlerts = false) =>
    createSelector(
        $metricsWithData,
        $alertsOnly,
        (
            metrics,
            alertsOnly,
        ): (
            | {
                  id: string;
                  name: string;
                  info?: string;
                  group: MetricGroup;
                  options?: EChartsOption;
                  tableOptions?: TableOptions;
              }
            | null
            | false
        )[] => {
            return metrics
                .filter(
                    ({ historyTemplate, history }) =>
                        historyTemplate !== undefined && history,
                )
                .map(
                    ({
                        id,
                        name,
                        historyTemplate,
                        group,
                        data,
                        thresholdProperties,
                        thresholdValue,
                        from,
                        to,
                        groupLabel,
                        labelGroups,
                    }) => {
                        if (!data) {
                            return null;
                        }

                        if (!historyTemplate) {
                            return false;
                        }

                        if (historyTemplate.type === ChartType.TABLE) {
                            if (historyTemplate.parameters.multiline) {
                                if (!groupLabel) {
                                    return false;
                                }

                                data = Object.values(
                                    data.reduce<{
                                        [key: string]: any;
                                    }>((acc, item) => {
                                        if (!item[groupLabel]) {
                                            return acc;
                                        }

                                        const group = item[
                                            groupLabel
                                        ] as string;

                                        if (!acc[group]) {
                                            acc[group] = {
                                                [groupLabel]: group,
                                                [historyTemplate.valueField]:
                                                    [],
                                            };
                                        }

                                        if (
                                            item[historyTemplate.valueField] !==
                                                null &&
                                            labelGroups
                                        ) {
                                            const labelGroupsWithoutGroupLabel =
                                                labelGroups.filter(
                                                    filteredGroup =>
                                                        filteredGroup !==
                                                        groupLabel,
                                                );

                                            for (const labelGroup of labelGroupsWithoutGroupLabel) {
                                                acc[group][labelGroup] =
                                                    item[labelGroup];
                                            }
                                        }

                                        acc[group][
                                            historyTemplate.valueField
                                        ].push({
                                            value: item[
                                                historyTemplate.valueField
                                            ],
                                            at: item._time,
                                        });
                                        return acc;
                                    }, {}),
                                );
                            }

                            return {
                                id,
                                name,
                                info: historyTemplate.info,
                                group: group as MetricGroup,
                                tableOptions: {
                                    noDataText: historyTemplate.noDataText,
                                    columns: historyTemplate.fields,
                                    rows: data
                                        .map(
                                            (item: {
                                                [key: string]:
                                                    | string
                                                    | number
                                                    | {
                                                          value: number | null;
                                                          at: string;
                                                      }[]
                                                    | undefined;
                                            }) => {
                                                const parsedData: {
                                                    [key: string]: {
                                                        value:
                                                            | string
                                                            | {
                                                                  value:
                                                                      | number
                                                                      | null;
                                                                  at: string;
                                                              }[];
                                                        originalValue:
                                                            | number
                                                            | string
                                                            | {
                                                                  value:
                                                                      | number
                                                                      | null;
                                                                  at: string;
                                                              }[];
                                                        color?: string;
                                                        colorMapping?: {
                                                            [
                                                                key: number
                                                            ]: string;
                                                            default: string;
                                                        };
                                                        alerting?: boolean;
                                                    };
                                                } = {};

                                                for (const column of historyTemplate.fields) {
                                                    const value: {
                                                        value:
                                                            | string
                                                            | {
                                                                  value:
                                                                      | number
                                                                      | null;
                                                                  at: string;
                                                              }[];
                                                        originalValue:
                                                            | number
                                                            | string
                                                            | {
                                                                  value:
                                                                      | number
                                                                      | null;
                                                                  at: string;
                                                              }[];
                                                        color?: string;
                                                        colorMapping?: {
                                                            [
                                                                key: number
                                                            ]: string;
                                                            default: string;
                                                        };
                                                        alerting?: boolean;
                                                    } = {
                                                        value: '',
                                                        originalValue:
                                                            item[column.name] ||
                                                            '',
                                                    };

                                                    let mapped = false;
                                                    const currentItem =
                                                        item[column.name];

                                                    if (
                                                        typeof currentItem !==
                                                        'object'
                                                    ) {
                                                        if (
                                                            Array.isArray(
                                                                column.mappings,
                                                            )
                                                        ) {
                                                            for (const mapping of column.mappings) {
                                                                if (
                                                                    currentItem ===
                                                                    mapping.from
                                                                ) {
                                                                    value.value =
                                                                        mapping.to;
                                                                    value.color =
                                                                        mapping.color;
                                                                    value.alerting =
                                                                        mapping.alerting;
                                                                    mapped =
                                                                        true;
                                                                    break;
                                                                }
                                                            }
                                                        } else {
                                                            switch (
                                                                column.mappings
                                                            ) {
                                                                case MappingFunction.TIME:
                                                                    value.value =
                                                                        moment(
                                                                            currentItem,
                                                                        ).format(
                                                                            'YYYY/MM/DD HH:mm:ss',
                                                                        );

                                                                    mapped =
                                                                        true;
                                                                    break;
                                                                case MappingFunction.THRESHOLD:
                                                                    if (
                                                                        typeof currentItem ===
                                                                            'number' &&
                                                                        typeof thresholdValue ===
                                                                            'number'
                                                                    ) {
                                                                        if (
                                                                            currentItem >
                                                                            thresholdValue
                                                                        ) {
                                                                            value.value =
                                                                                '' +
                                                                                currentItem;
                                                                            value.color =
                                                                                'red';
                                                                            value.alerting =
                                                                                true;
                                                                        } else {
                                                                            value.value =
                                                                                '' +
                                                                                currentItem;
                                                                            value.color =
                                                                                'green';
                                                                        }
                                                                    }

                                                                    mapped =
                                                                        true;
                                                                    break;
                                                            }
                                                        }

                                                        if (!mapped) {
                                                            value.value =
                                                                '' +
                                                                (!item[
                                                                    column.name
                                                                ]
                                                                    ? ''
                                                                    : item[
                                                                          column
                                                                              .name
                                                                      ]);
                                                        }

                                                        if (column.unit) {
                                                            value.value =
                                                                value.value +
                                                                ' ' +
                                                                column.unit;
                                                        }
                                                    } else {
                                                        value.value =
                                                            currentItem;

                                                        if (
                                                            column.name ===
                                                                historyTemplate.valueField &&
                                                            Array.isArray(
                                                                column.mappings,
                                                            )
                                                        ) {
                                                            for (const mapping of column.mappings) {
                                                                if (
                                                                    !value.colorMapping
                                                                ) {
                                                                    value.colorMapping =
                                                                        {
                                                                            default:
                                                                                'dark-400',
                                                                        };
                                                                }

                                                                if (
                                                                    mapping.color
                                                                ) {
                                                                    value.colorMapping[
                                                                        mapping.from
                                                                    ] =
                                                                        mapping.color;
                                                                }

                                                                if (
                                                                    !value.alerting &&
                                                                    mapping.alerting
                                                                ) {
                                                                    for (const currentValue of currentItem) {
                                                                        if (
                                                                            currentValue.value ===
                                                                            mapping.from
                                                                        ) {
                                                                            value.alerting =
                                                                                true;
                                                                            break;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    parsedData[column.name] =
                                                        value;
                                                }

                                                return parsedData;
                                            },
                                        )
                                        .filter(data => {
                                            if (!alertsOnly) {
                                                return true;
                                            }

                                            return (
                                                data[historyTemplate.valueField]
                                                    ?.alerting === true
                                            );
                                        })
                                        .sort((a, b) => {
                                            if (
                                                !historyTemplate.parameters
                                                    .sorting ||
                                                historyTemplate.parameters
                                                    .sorting.length === 0
                                            ) {
                                                return 0;
                                            }

                                            for (const sort of historyTemplate
                                                .parameters.sorting) {
                                                const aValue =
                                                    a[sort.field]
                                                        ?.originalValue;
                                                const bValue =
                                                    b[sort.field]
                                                        ?.originalValue;

                                                let result: number;

                                                if (
                                                    typeof aValue ===
                                                        'object' &&
                                                    typeof bValue === 'object'
                                                ) {
                                                    const aError =
                                                        aValue.reduce(
                                                            (acc, val) => {
                                                                if (
                                                                    val.value ===
                                                                    0
                                                                ) {
                                                                    return (
                                                                        acc + 1
                                                                    );
                                                                }

                                                                return acc;
                                                            },
                                                            0,
                                                        );

                                                    const bError =
                                                        bValue.reduce(
                                                            (acc, val) => {
                                                                if (
                                                                    val.value ===
                                                                    0
                                                                ) {
                                                                    return (
                                                                        acc + 1
                                                                    );
                                                                }

                                                                return acc;
                                                            },
                                                            0,
                                                        );

                                                    result = aError - bError;
                                                } else if (
                                                    typeof aValue ===
                                                        'string' &&
                                                    typeof bValue === 'string'
                                                ) {
                                                    result =
                                                        aValue.localeCompare(
                                                            bValue,
                                                        );
                                                } else if (
                                                    typeof aValue ===
                                                        'number' &&
                                                    typeof bValue === 'number'
                                                ) {
                                                    result = aValue - bValue;
                                                } else {
                                                    result = 0;
                                                }

                                                if (
                                                    aValue === '' &&
                                                    bValue !== ''
                                                ) {
                                                    result = 1;
                                                } else if (
                                                    aValue !== '' &&
                                                    bValue === ''
                                                ) {
                                                    result = -1;
                                                }

                                                if (sort.direction === 'desc') {
                                                    result = -result;
                                                }

                                                if (result !== 0) {
                                                    return result;
                                                }
                                            }

                                            return 0;
                                        }),
                                },
                            };
                        }

                        const {
                            type,
                            fields,
                            parameters: {
                                min,
                                max,
                                unit,
                                interval,
                                mappings,
                                abbreviation,
                                format,
                            },
                        } = historyTemplate;

                        const normalizedData = data.reduce(
                            (
                                acc: {
                                    [key: string]: (
                                        | string
                                        | number
                                        | undefined
                                    )[][];
                                },
                                fragment,
                            ) => {
                                const fieldAlias = getFieldAlias(
                                    fragment,
                                    fields,
                                );
                                return {
                                    ...acc,
                                    [fieldAlias]: [
                                        ...(acc[fieldAlias] || []),
                                        [fragment._time, fragment._value],
                                    ],
                                };
                            },
                            {},
                        );

                        const series: CustomSeriesOption[] = [
                            ...Object.entries(normalizedData || {}).map(
                                ([name, data]) => ({
                                    name,
                                    type,
                                    data,
                                    showSymbol: false,
                                }),
                            ),
                            {
                                // this type conversion is required because of echarts bad type definitions
                                type: ChartType.LINE as unknown,
                                markArea:
                                    thresholdProperties && data.length > 0
                                        ? {
                                              itemStyle: {
                                                  color: THRESHOLD_MARK_AREA_COLOR,
                                              },
                                              data: [
                                                  thresholdProperties.type ===
                                                  ThresholdType.ABOVE
                                                      ? [
                                                            {
                                                                yAxis: thresholdValue,
                                                            },
                                                            {},
                                                        ]
                                                      : [
                                                            {},
                                                            {
                                                                yAxis: thresholdValue,
                                                            },
                                                        ],
                                              ],
                                          }
                                        : {},
                            },
                        ] as CustomSeriesOption[];

                        const dataZoom = (
                            showSliders
                                ? [
                                      // horizontal scroll
                                      {
                                          type: 'slider',
                                          xAxisIndex: 0,
                                          filterMode: 'weakFilter',
                                          height: 20,
                                          bottom: 20,
                                          start: 0,
                                          end: 100,
                                          handleIcon:
                                              'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                                          handleSize: '80%',
                                          showDetail: false,
                                      },
                                  ]
                                : []
                        ) as DataZoomComponentOption[];

                        const chartData: {
                            id: string;
                            name: string;
                            group: MetricGroup;
                            info?: string;
                            options: EChartsOption;
                        } = {
                            id,
                            name,
                            group: group as MetricGroup,
                            info: historyTemplate.info,
                            options: {
                                legend: {
                                    show:
                                        historyTemplate.showLegend ===
                                            undefined ||
                                        historyTemplate.showLegend,
                                    type: 'scroll',
                                    orient: 'horizontal',
                                    align: 'left',
                                    data: Object.keys(normalizedData || {}),
                                },
                                tooltip: {
                                    trigger: 'axis',
                                    formatter: (params: unknown) => {
                                        return tooltipFormatter(
                                            // this type conversion is required because of echarts bad type definitions
                                            params as CallbackDataParams[],
                                            id,
                                            mappings,
                                            abbreviation,
                                            unit,
                                            false,
                                            format,
                                        );
                                    },
                                },
                                xAxis: {
                                    type: 'time',
                                    min: from,
                                    max: to,
                                    axisLine: {
                                        show: true,
                                    },
                                    axisLabel: {
                                        rotate: 0,
                                        margin: 15,
                                        hideOverlap: true,
                                        width: 150,
                                        align: 'left',
                                        formatter: (value: number) =>
                                            moment(value).format(
                                                X_AXIS_LABEL_FORMAT,
                                            ),
                                    },
                                    silent: false,
                                    splitLine: {
                                        show: false,
                                    },
                                },
                                yAxis: {
                                    type: 'value',
                                    scale: true,
                                    min,
                                    max,
                                    minInterval: interval,
                                    axisLine: {
                                        show: true,
                                    },
                                    axisLabel: {
                                        margin: 20,
                                        formatter: (value: number) =>
                                            valueFormatter(
                                                value,
                                                mappings,
                                                abbreviation,
                                                unit,
                                                format,
                                            ),
                                    },
                                },
                                grid: {
                                    top: 50,
                                    bottom: 50,
                                    left: 10,
                                    right: 10,
                                    containLabel: true,
                                },
                                dataZoom,
                                series,
                                animationEasing: 'elasticOut',
                                animationDelayUpdate: (idx: number) => idx * 5,
                            },
                        };

                        if (data.length === 0) {
                            chartData.options.title = {
                                show: true,
                                textStyle: {
                                    color: 'grey',
                                    fontSize: 20,
                                },
                                text: 'NO DATA',
                                left: 'center',
                                top: 'center',
                            };

                            (chartData.options.xAxis as XAXisOption).show =
                                false;
                            (chartData.options.yAxis as YAXisOption).show =
                                false;
                        }

                        return chartData;
                    },
                );
        },
    );

export const $deviceCareConfig = createSelector(
    $selectedSite,
    site => site?.deviceCare,
);

export const $selectedPeriod = createSelector(
    $deviceCareConfig,
    config => config?.selectedPeriod,
);

export const $settingsMetrics = createSelector(
    $metricsWithData,
    (metrics: MetricWithData[]): SettingsMetricModel[] =>
        (metrics || []).map(metric => ({
            id: metric.id,
            label: metric.name,
            state: metric.state,
            alert: metric.alert,
            report: metric.report,
            dashboard: metric.dashboard,
            history: metric.history,
            hasDashboardTemplate: metric.dashboardTemplate !== undefined,
            hasHistoryTemplate: metric.historyTemplate !== undefined,
            hasAlertTemplate: metric.alertTemplate !== undefined,
            overridable: metric.overridable || false,
            alertTemplate: metric.alertTemplate,
            mappings: metric.dashboardTemplate
                ? metric.dashboardTemplate.parameters.mappings.filter(
                      mapping => mapping.from !== 0,
                  )
                : [],
            threshold: metric.thresholdProperties
                ? {
                      value:
                          metric.thresholdValue /
                          (metric?.historyTemplate?.parameters?.abbreviation
                              ?.value || 1),
                      valueAbbreviation:
                          metric?.historyTemplate?.parameters?.abbreviation
                              ?.value || 1,
                      unit: `${
                          metric.historyTemplate?.parameters?.abbreviation
                              ?.prefix || ''
                      }${
                          metric?.thresholdProperties?.unit ||
                          metric?.historyTemplate?.parameters?.unit ||
                          metric?.dashboardTemplate?.parameters?.unit ||
                          ''
                      }`,
                      symbol:
                          ThresholdToSymbolMap[
                              metric.thresholdProperties.type
                          ] || '',
                      state: metric.thresholdProperties.state,
                      editable: metric.thresholdProperties.editable,
                  }
                : undefined,
        })),
);

export const $settingsData = createSelector(
    $settingsMetrics,
    $deviceCareConfig,
    (
        metrics: SettingsMetricModel[],
        deviceCare: DeviceCare | undefined,
    ): SettingsDataModel => ({
        metrics,
        deviceCare,
    }),
);

export const $metricsUpdateLoading = createSelector(
    $sitesState,
    ({ metricsUpdateLoading }): boolean => metricsUpdateLoading,
);

export const $reportUpdateLoading = createSelector(
    $sitesState,
    ({ reportUpdateLoading }): boolean => reportUpdateLoading,
);

export const $metricGroups = createSelector($metricsWithData, metrics => [
    ...new Set(metrics.map(({ group }) => group)),
]);

export const $deviceCareInstallation = createSelector(
    $sitesState,
    sites => sites.installationParameters,
);

export const $metricWithData = (metricId: string) =>
    createSelector($metricsWithData, metrics =>
        metrics.find(metric => metric.id === metricId),
    );

export const $siteAlerts = createSelector(
    $sitesState,
    ({ alerts }): AlertInterval[] | null => alerts,
);

export const $dcActivation = createSelector($selectedSite, site => {
    if (!site || !site.deviceCare) {
        return null;
    }

    return site.deviceCare.dcActivation;
});

export const $siteAlertDays = createSelector(
    $siteAlerts,
    $dcActivation,
    $settingsMetrics,
    (alerts, activation, settingsMetrics) => {
        if (!alerts) {
            return {};
        }

        const today = moment();
        const dates: AlertsWithMetricSettings = {};

        for (const alert of alerts) {
            const from = moment(alert.from);
            const to = alert.to ? moment(alert.to) : today;

            const metricSettings = settingsMetrics.find(
                metric => metric.id === alert.metric,
            );

            if (!metricSettings) {
                continue;
            }

            const isAlertedToEmail =
                settingsMetrics.find(
                    settingsMetric => settingsMetric.id === alert.metric,
                )?.alert || false;

            if (!isAlertedToEmail) {
                continue;
            }

            while (!from.isAfter(to, 'days')) {
                const currentDayFormatted = from.format('YYYY-MM-DD');

                if (!dates[currentDayFormatted]) {
                    dates[currentDayFormatted] = {
                        partial: false,
                        alerts: [
                            {
                                ...alert,
                                metric: metricSettings.id,
                                metricSettings,
                            },
                        ],
                        state: State.OK,
                    };
                } else {
                    dates[currentDayFormatted].alerts.push({
                        ...alert,
                        metric: metricSettings.id,
                        metricSettings,
                    });
                }

                if (isAlertedToEmail) {
                    dates[currentDayFormatted].state = State.ERROR;
                }

                from.add(1, 'day');
            }
        }

        const dcActivationDate = activation ? moment.unix(activation) : null;

        if (dcActivationDate) {
            const maxHistoryDate = moment().subtract(6, 'months');
            const currentDay = dcActivationDate.isBefore(maxHistoryDate)
                ? maxHistoryDate
                : dcActivationDate;

            while (currentDay.isSameOrBefore(today, 'days')) {
                const currentDayFormatted = currentDay.format('YYYY-MM-DD');

                if (!dates[currentDayFormatted]) {
                    dates[currentDayFormatted] = {
                        partial: false,
                        alerts: [],
                        state: State.OK,
                    };
                }

                currentDay.add(1, 'day');
            }
        }

        const todayFormatted = today.format('YYYY-MM-DD');

        if (dates[todayFormatted]) {
            dates[todayFormatted].partial = true;
        }

        return dates;
    },
);

export const $siteReports = createSelector(
    $sitesState,
    ({ reports }): Report[] => reports,
);

function getFormattedMetricValue(
    value = 0,
    parameters?: MetricParameters,
): string {
    if (parameters?.format === FormatType.DURATION) {
        return moment.duration(value, 'seconds').humanize();
    }

    const calculatedValue =
        parameters?.format !== FormatType.DECIMAL
            ? Math.round(value / (parameters?.abbreviation?.value || 1))
            : Math.round(
                  (value / (parameters?.abbreviation?.value || 1)) * 10,
              ) / 10;

    const unit = `${parameters?.abbreviation?.prefix || ''}${
        parameters?.unit || ''
    }`;

    return `${calculatedValue} ${unit}`;
}

function getMetricLabel(id: string, fields: MetricField[] | undefined) {
    if (!fields || fields.length !== 1) {
        return id.toUpperCase();
    }

    return fields[0].alias;
}

function getMetricValueState(
    value: number | null,
    thresholdValue: number,
    thresholdProperties?: MetricThresholdProperties,
): StatusEnum {
    if (value === null) {
        return StatusEnum.UNDEFINED;
    }

    if (thresholdProperties?.type === ThresholdType.ABOVE) {
        return value > thresholdValue ? StatusEnum.BAD : StatusEnum.GOOD;
    }
    if (thresholdProperties?.type === ThresholdType.BELOW) {
        return value < thresholdValue ? StatusEnum.BAD : StatusEnum.GOOD;
    }

    return StatusEnum.UNDEFINED;
}

export const $metricFilteringData = (metricId: string) =>
    createSelector(
        $metricsWithData,
        (
            metrics: MetricWithData[],
        ): MetricFilteringDataFragment[] | undefined =>
            (metrics || [])
                .map(metric => ({
                    id: metric.id,
                    filteringData: metric.filteringData,
                }))
                .filter(
                    (
                        metric,
                    ): metric is {
                        id: string;
                        filteringData: MetricFilteringDataFragment[];
                    } => metric.filteringData != undefined,
                )
                .find(metric => metric.id === metricId)?.filteringData,
    );

export const $dashboardSingleStatData = createSelector(
    $metricsWithData,
    $selectedSiteId,
    (metrics: MetricWithData[], id): DashboardSingleStatValueModel[] =>
        (metrics || [])
            .filter(
                metric => metric.dashboardTemplate != null && metric.dashboard,
            )
            .filter(
                metric =>
                    metric.dashboardTemplate?.type === ChartType.SINGLE_STAT,
            )
            .map(metric => {
                const value = metric.dashboardData?.length
                    ? metric.dashboardData[0]._value
                    : null;

                return {
                    id: metric.id,
                    version: metric.version,
                    loading: metric.dashboardData === undefined,
                    status: getMetricValueState(
                        value || null,
                        metric.thresholdValue,
                        metric.thresholdProperties,
                    ),
                    value:
                        value === null
                            ? 'NO DATA'
                            : getFormattedMetricValue(
                                  value,
                                  metric.dashboardTemplate?.parameters,
                              ),
                    info: metric.dashboardTemplate?.info?.replace(
                        /\$SITE_ID/gm,
                        id ? id : '',
                    ),
                    full: metric.dashboardTemplate?.full,
                    label: getMetricLabel(
                        metric.id,
                        metric.dashboardTemplate?.fields,
                    ),
                    threshold: metric.dashboardTemplate?.showThreshold
                        ? metric.thresholdValue.toString()
                        : undefined,
                };
            }),
);

export const $dashboardStackedChartData = createSelector(
    $metricsWithData,
    $selectedSiteId,
    (metrics: MetricWithData[], id): DashboardRatioBarValueModel[] =>
        (metrics || [])
            .filter(
                metric =>
                    metric.dashboardData !== null &&
                    metric.dashboardTemplate != null &&
                    metric.dashboard,
            )
            .filter(
                metric =>
                    metric.dashboardTemplate?.type === ChartType.STACKED_BAR,
            )
            .map(metric => {
                if (!metric) {
                    return { label: '', data: [] };
                }

                if (!metric.dashboardData) {
                    return { label: metric.name, data: [] };
                }

                const counter: {
                    [key: string]: {
                        count: number;
                        properties: MetricMapping;
                    };
                } = {};

                if (metric.dashboardTemplate?.parameters.mappings) {
                    for (const mapping of metric.dashboardTemplate?.parameters
                        .mappings || []) {
                        counter[mapping.from.toString()] = {
                            count: 0,
                            properties: mapping,
                        };
                    }
                }

                (metric.dashboardData || []).forEach(item => {
                    if (item._value === undefined) {
                        return;
                    }

                    if (counter[item._value] === undefined) {
                        counter[item._value] = {
                            count: 0,
                            properties: {
                                from: item._value,
                                to: `${item._value}`,
                                color: '#888888',
                            },
                        };
                    }

                    counter[item._value].count++;
                });

                const total = metric.dashboardData.length;

                if (total === 0) {
                    counter[-1] = {
                        count: 0,
                        properties: {
                            from: -1,
                            to: 'NO DATA',
                        },
                    };
                }

                return {
                    id: metric.id,
                    version: metric.version,
                    label: metric.name,
                    info: metric.dashboardTemplate?.info?.replace(
                        /\$SITE_ID/gm,
                        id ? id : '',
                    ),
                    data: Object.keys(counter)
                        .map(key => {
                            let value =
                                Math.round(
                                    (counter[key].count / total) * 1000,
                                ) / 10;

                            if (isNaN(value)) {
                                value = 0;
                            }

                            return {
                                label: counter[key].properties.to,
                                color: counter[key].properties.color
                                    ? counter[key].properties.color
                                    : '#888888',
                                value,
                                count: counter[key].count,
                            };
                        })
                        .filter(
                            item =>
                                item.value !== 0 || item.label === 'NO DATA',
                        )
                        .map(item => ({
                            label: item.label,
                            color: item.color,
                            value: `${item.value}`,
                            count: `${item.count}`,
                        })),
                };
            }),
);
